<template>
  <div class="no-select">
    <v-overlay :z-index="100" :value="bIng" :opacity="1" :absolute="true" color="#ffffffC0">
      <v-progress-circular indeterminate size="80" color="black"></v-progress-circular>
    </v-overlay>  
    <div id="MapView" style="z-index : 10; background:#ffffff;" @mouseup="onMouseUp" @mousedown="onMouseDown" @mousemove="onMouseMove" @mouseleave="onMouseLeave">
      <div id="ui" :style="'position:absolute; z-index: 1; width:'+windowWidth+'px; height: '+windowHeight+'px;overflow:hidden;'">        
        <div style="position:absolute; z-index: 2;">
          <div v-for="(item, i) in zUI_POI_View" :key="'POI_'+i">
            <div class="poi_ui mouse-cur" :style="'position:absolute; left:'+(item.iUi_x-50).toString()+'px; top: '+(item.iUi_y-70).toString()+'px; '" >              
              <div v-if="item.iNowCount != 0" class="box_ap_FontQty text-center" style="position: relative;">                
                {{item.iNowCount}}
              </div>
            </div>            
            <div v-if="item.isText" class="poi_ui mouse-cur no-select" :style="'position:absolute; left:'+(item.iUi_x-15).toString()+'px; top: '+(item.iUi_y-42).toString()+'px;'" >
              <div :class="item.isSel?'animated-image':'' + ' mouse-cur'" style="height:30px; width:30px;  overflow: hidden;" >
              </div>
            </div>
          </div>

          <div v-for="(item, i) in zUI_Device_View" :class="getCSS(item)" :key="'Device_Img'+i" :style="'position:absolute; left:'+(item.iUi_x-10).toString()+'px; top: '+(item.iUi_y-10).toString()+'px;'">           
            <v-tooltip right style="z-index: 100 !important; background-color: blue;" content-class="custom-tooltip">
              <template v-slot:activator="{ on }">   
                <div v-on="on" style="width:20px; height:20px;" >                                                                        
                  <div v-if="item.isSensor && item.cSnState =='2' " class="GAS_BOX_Danger noselect"></div>
                  <div v-if="item.isSensor && item.cSnState =='1' " class="GAS_BOX_Danger noselect" style="box-shadow: 0 0 80px #ffff1dFF;"></div>
                </div>
              </template>
              <div v-if="item.isSensor" style="width: 220px; height: 162px;">
                <div>Sender Id : {{item.cDeviceId}}</div>
                <div>Mac : {{item.cMac}}</div>
                <div>Emergency Level : {{item.cSnState}}/{{item.cSnState=="1"?"경고":item.cSnState=="2"?"위험":"정상"}}</div>
                <div>OXYGEN : ({{item.cSENS_OXYGEN_Lev}}){{item.cSENS_OXYGEN_Data}}</div>
                <div>CARBON_MONOXIDE : ({{item.cSENS_CARBON_MONOXIDE_Lev}}){{item.cSENS_CARBON_MONOXIDE_Data}}</div>
                <div>HYDROGEN : ({{item.cSENS_HYDROGEN_Lev}}){{item.cSENS_HYDROGEN_Data}}</div>
                <div>METHAN : ({{item.cSENS_METHAN_Lev}}){{item.cSENS_METHAN_Data}}</div>                
              </div>                    
              <div v-else>{{item.cDeviceId}}:{{item.cMac.replace(/(.{4})(?=.)/g, "$1-")}} </div>
            </v-tooltip>            
          </div>

          <div v-if="zUI_User_View.length != 0 && !isChange">
            <div v-for="(item, i) in zUI_User_View" class="UserNone noselect" :key="'User'+i" :style="'position:absolute; left:'+(item.iUi_x).toString()+'px; top: '+(item.iUi_y+40).toString()+'px;'">
            <!-- <div v-for="(item, i) in zUI_User_View" class="UserNone" :key="'User'+i" :style="'position:absolute; left:'+(item.iUi_x-10).toString()+'px; top: '+(item.iUi_y-10).toString()+'px;'"> -->
              <v-tooltip right style="z-index: 100 !important; background-color: blue;" content-class="custom-tooltip">
                <template v-slot:activator="{ on }">                                   
                  <img src="@/assets/man.png" v-on="on"  class="User_Box noselect" style="width:35px; filter: opacity(0.5) drop-shadow(0 0 0 blue);" />
                  <div v-on="on" v-if="item.isSOS" class="SOS_BOX noselect"></div>
                  
                </template>                    
                <div>{{item.cUserNm}}/{{item.cCorpNm}}/{{item.cPartNm}}</div>
              </v-tooltip>
            </div>
          </div>
        </div>
        <svg :width="windowWidth" :height="windowHeight">                    
          <g v-for="(item, i) in zUI_POI_View" :key="'POS_Text_' + i" >
            <text :class="(item.isSel?'animated-image':'')+ ' label_css_ui mouse-cur'" :x="item.iUi_x" :y="item.iUi_y" text-anchor="middle" tabindex="0" @keydown.enter="onPOIClick(item)">{{item.cNm}}</text>
          </g>
        </svg>                 
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from "three";

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min.js';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';

//이미지 드레그 방지
document.addEventListener('dragstart', function(event) {
    if (event.target.tagName === 'IMG') {
        event.preventDefault();
    }
});

var fOldWidth = 0;                                    
var fOldHeight = 0;                                   

var container = null;
var scene = null;
var camera = null;

var renderer = null;
var controls = null;
var objMap = null;
var group = null;

var max_width = 2048;
var rate = 16.8;

export default {  
  props: ["windowWidth", "windowHeight"],
  data() {
    return {
      isChange : false,
      isDragging: false,
      startX : 0,
      startY : 0,
      iZoom : 0,        
      bIng : true,
      isLoading : false,
      isBuilding : true,
      isInMap : true,
      zUI_TR : [],           

      zMap : [],

      zPOI : [],
      zPOIBuffer : [],
      
      zUI_POI : [],     
      zUI_POI_View : [],
      zUI_Device : [],
      zUI_Device_View : [],
      zUI_User : [],      
      zUI_User_View : [],      
      dialog : false,      
      cSearch : "",
      zBufferSearchUser : [],
      zBufferSearchPOI : [],
      zBufferSearchTR : [],
      isUpdateWindow : false,

      HomePos : {
        Camera : { x:-40, y:85, z:60},
        // Camera : { x:-40, y:40, z:60},
        // Camera : { x:-50, y:30, z:60},
        Ctrl : { x:0, y:0, z:0},
      },
      //HomePos : {
      //  Camera : { x:-10, y:85, z:80},
      //  Ctrl : { x:0, y:0, z:0},
      //},
    };
  },

  watch: {
  },

  mounted() {
    // setTimeout(() => {
    //   this.init();
    //   this.animate();      
    // }, 10);
    
  },
  beforeUnmount(){        
    controls.removeEventListener('change', this.ControlsChange);
  },
  methods: {
    getCSS(item) {
      if (item.isSensor) {
        return item.isDel? 'box_sn_state_del': (item.isOnline ?'box_sn_state_on':'box_sn_state_off')
      } else {
        return item.isDel? 'box_ap_state_del': (item.isOnline ?'box_ap_state_on':'box_ap_state_off')
      }
    },    
    onMouseDown(event) {
      this.isDragging = false;
      this.startX = event.clientX;
      this.startY = event.clientY;
    },
    onMouseMove(event) {
      if (event.buttons === 1) {  // 마우스 왼쪽 버튼이 눌린 상태
        this.isDragging = true;
      }
    },
    onMouseUp(event) {
      if (!this.isDragging) {
        this.onClick(event);
      }
      this.isDragging = false;
    },
    onMouseLeave(event) {
      console.log(event);
      this.isDragging = false;
    },

    init() {
      this.zUI_POI = [];
      this.zUI_POI_View = [];
      this.zUI_Device = [];
      this.zUI_Device_View = [];
      this.zUI_User = [];
      this.zUI_User_View = [];

      // this.InitHome();
      container = document.getElementById("MapView");

      renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });      
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.shadowMap.enabled = true; // 그림자 활성화
      renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      
      container.appendChild(renderer.domElement);
      renderer.outputEncoding = THREE.sRGBEncoding;
      
      const pmremGenerator = new THREE.PMREMGenerator( renderer );
      // console.log(pmremGenerator);

      scene = new THREE.Scene();
      scene.background = new THREE.Color(0xF9F9F9);      
      
      scene.environment = pmremGenerator.fromScene( new RoomEnvironment( renderer ), 0.04 ).texture;
      
      this.SetupCamera();


      const ambientLight = new THREE.AmbientLight(0xeeeeee); // 은은한 빛
      scene.add(ambientLight);      
 
      
      this.SetupControls();
                          

      renderer.autoClear = true; 
      // this.GetZoom();

      this.resize();
      window.onresize = this.resize.bind(this);

      this.isUpdateWindow = true;
      this.resetTimeOut();      

      let g = new THREE.SphereGeometry( 1, 32, 32);        
      const material = new THREE.MeshBasicMaterial( { color: 0xff0000, wireframe: false, transparent: false } );    
      let mesh1 = new THREE.Mesh( g, material );        
      mesh1.position.set(0,0,0);
      mesh1.userData.cType = "sel";        
                
      group = new THREE.Group();
			scene.add( group );      


    },  

    onPOIClick(item) {
      // console.log("onPOIClick", item);
      this.$emit("onClick", "POI", item.cPOIId);
    },

    resetTimeOut(){
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        this.isUpdateWindow = false;
      }, 2000);
    },
     
    ClearDataItem(zData, _scene){
      for (var i = zData.length; i--;) {                      
        _scene.remove(zData[i]);
        zData.splice(i, 1); 
      }   
      zData = [];
    },

    onProgress( xhr ) {
      if ( xhr.lengthComputable ) {
        const percentComplete = xhr.loaded / xhr.total * 100;
        console.log( 'model ' + Math.round( percentComplete, 2 ) + '% downloaded' );
      }
		},    

   


    loadObj(path) {
      this.init();
      this.animate();   

      // console.log(path, cFloorId);
      const loader = new GLTFLoader();
      // loader.load('/map/gn_1F.gltf',

      console.log("/map/" + path);
      loader.load("/map/" + path,
          (gltf) => {
              const object = gltf.scene;
                object.traverse(function (child) {
                  if (child.isMesh) {
                      child.castShadow = true; // 모델의 각 메쉬에 그림자 활성화
                      child.receiveShadow = true; // 모델의 각 메쉬가 그림자 받기 활성화
                      
                      if (["wall_1","wall_2","wall_3","wall_4","wall_5","wall_6","wall_7","wall_8","wall_9","outline", "outline001", "out_wall"].includes(child.name)) {
                        child.material = new THREE.MeshBasicMaterial({
                            color: '#ffffff',
                        });
                        child.castShadow = true; // 모델의 각 메쉬에 그림자 활성화
                        child.receiveShadow = true; // 모델의 각 메쉬가 그림자 받기 활성화
                        child.material.transparent = true; // 투명도 활성화
                        child.material.opacity = 0.85; // 투명도 설정 (0.0부터 1.0까지)
                        
                        const edges = new THREE.EdgesGeometry(child.geometry);
                        const line = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({ color: 0x444444 }));
                        child.add(line);
                      } else if (child.name === "plan") {
                        child.material = new THREE.MeshStandardMaterial({
                            // color: child.material.color,
                            color: "#707070",
                            metalness: 1,
                            roughness: 1
                        });

                      } else if (["bottom001","bottom002","bottom",].includes(child.name)) {                        
                      // } else if (child.name === "bottom.001") {
                        child.material = new THREE.MeshStandardMaterial({
                            // color: child.material.color,
                            color: "#303037",
                            metalness: 1,
                            roughness: 1
                        });
                        child.castShadow = true; // 모델의 각 메쉬에 그림자 활성화
                        child.receiveShadow = true; // 모델의 각 메쉬가 그림자 받기 활성화
                      } else {
                        child.material = new THREE.MeshStandardMaterial({                        
                          color: child.material.color,
                            metalness: 1,
                            roughness: 1
                        });
                      }
                  }
              });
              objMap = object;
              
              objMap.scale.set(0.22, 0.22, 0.22);          
              objMap.visible = true;

              console.log("OK");
              
              this.zMap.push(objMap);
              scene.add(objMap);

              this.bIng = false;
          },
          undefined,
          (error) => {
              console.error('An error happened', error);
              this.bIng = false;
          }
      );
    },

		onError() {
      console.log("onLoad FBX Error");
    },

    changeBackgroundColor(fromColor, toColor, duration) {
      // 초기 색상과 목표 색상을 THREE.Color 객체로 생성
      const startColor = new THREE.Color(fromColor);
      const endColor = new THREE.Color(toColor);

      // 초기 색상값을 객체로 저장
      const colorObject = {
        r: startColor.r,
        g: startColor.g,
        b: startColor.b
      };

      // Tween 애니메이션 정의
      new TWEEN.Tween(colorObject)
        .to({
          r: endColor.r,
          g: endColor.g,
          b: endColor.b
        }, duration)
        .onUpdate(() => {
          // 업데이트마다 scene.background를 새로운 색상으로 설정
          scene.background = new THREE.Color(colorObject.r, colorObject.g, colorObject.b);
        })
        .start();
    },

    SetupCamera() {      
      camera = new THREE.PerspectiveCamera(30, this.windowWidth / this.windowHeight, 0.1, 2000 );			      
			camera.position.set( 10, 50, 150 );
      scene.add(camera);  
    },
    
    SetupLight(...pos) {      
      const color = 0xffffff;            
      const intensity = 0.00005;
      // const light = new THREE.AmbientLight(color, intensity);
      const light = new THREE.DirectionalLight(color, intensity);
      light.position.set(...pos);
      scene.add(light);
    },


    animate() {              
      this.Reset2DPosition(this.zPOI, this.zPOIBuffer);

      for (let i = 0; i < this.zUI_POI.length; i++) {
        let xy = this.getScreenPositionCSS2(this.zUI_POI[i].position);           
        this.zUI_POI[i].userData.iUi_x = xy.x;
        this.zUI_POI[i].userData.iUi_y = xy.y;     
      }

      for (let i = 0; i < this.zUI_Device.length; i++) {
        let xy = this.getScreenPositionCSS2(this.zUI_Device[i].position);           
        this.zUI_Device[i].userData.iUi_x = xy.x;
        this.zUI_Device[i].userData.iUi_y = xy.y;     
      }

      for (let i = 0; i < this.zUI_User.length; i++) {
        let xy = this.getScreenPositionCSS2(this.zUI_User[i].position);           
        this.zUI_User[i].userData.iUi_x = xy.x;
        this.zUI_User[i].userData.iUi_y = xy.y;     
      }



      for (let i = 0; i < this.zUI_POI.length; i++) {
        for (let j = 0; j < this.zUI_POI_View.length; j++) {
          if (this.zUI_POI[i].userData.cSer == this.zUI_POI_View[j].cSer) {
            this.zUI_POI_View[j].iUi_x = this.zUI_POI[i].userData.iUi_x;
            this.zUI_POI_View[j].iUi_y = this.zUI_POI[i].userData.iUi_y;
            break;
          }
        }
      }      

      for (let i = 0; i < this.zUI_Device.length; i++) {
        for (let j = 0; j < this.zUI_Device_View.length; j++) {
          if (this.zUI_Device[i].userData.cSer == this.zUI_Device_View[j].cSer) {
            this.zUI_Device_View[j].iUi_x = this.zUI_Device[i].userData.iUi_x;
            this.zUI_Device_View[j].iUi_y = this.zUI_Device[i].userData.iUi_y;
            break;
          }
        }
      }

      for (let i = 0; i < this.zUI_User.length; i++) {
        for (let j = 0; j < this.zUI_User_View.length; j++) {
          if (this.zUI_User[i].userData.cUserId == this.zUI_User_View[j].cUserId) {
            this.zUI_User_View[j].iUi_x = this.zUI_User[i].userData.iUi_x;
            this.zUI_User_View[j].iUi_y = this.zUI_User[i].userData.iUi_y;
            break;
          }
        }
      }

      TWEEN.update();
      renderer.render(scene, camera);                        			
			
      controls.update();            

      if (this.isUpdateWindow) {
       requestAnimationFrame(this.animate);
      }
    },

    resize() {                
      setTimeout(() => {        
        if (camera == null) {return}

        camera.aspect = this.windowWidth / this.windowHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(this.windowWidth, this.windowHeight);

        requestAnimationFrame(this.animate);      
      }, 10);

    },

    SetupControls() {
      controls = new OrbitControls( camera, document.getElementById("MapView") );
      
      controls.minDistance = 1;
      controls.maxDistance = 500; 
      controls.maxPolarAngle = Math.PI/2; 

      controls.target.set( 0, 0, 0 );
			controls.update();
			controls.enablePan = true;
			controls.enableDamping = true;
      controls.addEventListener('change', this.ControlsChange);

      
      controls.update();        
    },
   

    fadeObject(object, toOpacity, duration) {
      object.traverse(function (child) {
        if (child.isMesh) {
          const fromOpacity = { opacity: child.material.opacity };
          new TWEEN.Tween(fromOpacity)
            .to({ opacity: toOpacity }, duration)
            .onUpdate(() => {
              child.material.transparent = true;
              child.material.opacity = fromOpacity.opacity;
            })
            .start();
        }
      });
    },

    scaleObjectZ(object, toScaleZ, duration) {
    const fromScaleZ = { z: object.scale.z };

    new TWEEN.Tween(fromScaleZ)
      .to({ z: toScaleZ }, duration)
      .onUpdate(() => {
        object.scale.z = fromScaleZ.z;
      })
      .start();
    },

    iniMap() {
      camera.position.x = 0;
      camera.position.y = 0;
      camera.zoom = 1;
      fOldWidth = 0;
      fOldHeight = 0;
      this.SetResize();
    },

    SetResize() {
      setTimeout(() => {
        if (fOldWidth == this.windowWidth && fOldHeight == this.windowHeight) {
          return;
        }
        fOldWidth = this.windowWidth;
        fOldHeight = this.windowHeight;
        renderer.setSize(this.windowWidth, this.windowHeight);
        var iZoom = camera.zoom;
        camera = new THREE.OrthographicCamera(
          this.windowWidth / -100,
          this.windowWidth / 100,
          this.windowHeight / 100,
          this.windowHeight / -100,
          0.1,
          1000
        );
        camera.position.z = 1;
        camera.zoom = iZoom;
        camera.setViewOffset(
          this.windowWidth,
          this.windowHeight,
          0,
          0, 
          this.windowWidth,
          this.windowHeight,
        );
        controls = new OrbitControls(camera, renderer.domElement);

        controls.zoomSpeed = 1.2; 
        controls.panSpeed = 0.8; 
        controls.minDistance = 5; 
        controls.maxDistance = 100; 
        controls.screenSpacePanning = true; 
      }, 100);
    },

    getScreenPosition(p){      
      var xy = this.toScreenXY(p, camera);
      return xy;
    },
    toScreenXY(v_, camera_) {
      var p = new THREE.Vector3(v_.x, v_.y, v_.z);
      var vector = p.project(camera_);

      vector.x = Math.round((vector.x + 1) / 2 * this.windowWidth);
      vector.y = Math.round(-(vector.y - 1) / 2 * this.windowHeight);

      return { x: vector.x, y: vector.y };
    },

    getScreenPositionNor(p){      
      var xy = this.toScreenXYNor(p, camera);
      return xy;
    },

    toScreenXYNor(v_, camera_) {
      var p = new THREE.Vector3(v_.x, v_.y, v_.z);
      var vector = p.project(camera_);

      vector.x = Math.round((vector.x + 1) / 2 * this.windowWidth) - this.windowWidth / 2 + 15;
      vector.y = -1*(Math.round(-(vector.y - 1) / 2 * this.windowHeight) - this.windowHeight / 2) - 8;
      return { x: vector.x, y: vector.y };      
    },

    
  

    IniPOI(zPOI, sMapGroupId, sBuildingId ) {     
      for (let i = 0; i < this.zUI_POI.length; i++) {                
        this.zUI_POI.remove(this.zUI_POI[i]);
        this.zUI_POI[i] = null;
        this.zUI_POI.splice(i, 1);      
      }

      for (let i = 0; i < this.zUI_POI_View.length; i++) {                        
        this.zUI_POI_View.splice(i, 1);      
      }
      this.zUI_POI_View = [];

      for (let j = 0; j < zPOI.length; j++){                 
        if (zPOI[j].cMapGroupId != sMapGroupId || zPOI[j].cBuildingId != sBuildingId || zPOI[j].cMapId == '00') {
          continue;
        }
        let g = new THREE.SphereGeometry( 1, 1, 1);
        const material = new THREE.MeshBasicMaterial( { color: 0x333333, wireframe: false, transparent: false } );    

        let mesh1 = new THREE.Mesh( g, material );        
        
        
        mesh1.position.set((parseFloat(zPOI[j].x) - max_width/2) / rate, 1, ( (max_width/2 - parseFloat(zPOI[j].y))) / rate);
        mesh1.userData.cType = "poi";
        mesh1.userData.cSer = parseInt(j);
        mesh1.userData.iUi_x = -100;
        mesh1.userData.iUi_y = -100;
        mesh1.userData.cNm = zPOI[j].cPOINm;
        mesh1.userData.cType = "poi";
        mesh1.userData.cPOIId = zPOI[j].cPOIId;        
        mesh1.userData.cMapGroupId = zPOI[j].cMapGroupId;        
        mesh1.userData.cBuildingId = zPOI[j].cBuildingId;        
        mesh1.userData.cMapId = zPOI[j].cMapId;        
        mesh1.userData.isText = false;
        mesh1.userData.iNowCount = zPOI[j].iCount;


        mesh1.visible = false;          
        scene.add(mesh1);
        this.zUI_POI.push(mesh1);    
      }      
      
      for (let i = 0; i < this.zUI_POI.length; i++) {        
        let a = {
          cSer : this.zUI_POI[i].userData.cSer,

          iUi_x : this.zUI_POI[i].userData.iUi_x,
          iUi_y : this.zUI_POI[i].userData.iUi_y,
          isText : this.zUI_POI[i].userData.isText,
          cNm : this.zUI_POI[i].userData.cNm,
          cType : this.zUI_POI[i].userData.cType,
          cPOIId : this.zUI_POI[i].userData.cPOIId,          
          cMapGroupId : this.zUI_POI[i].userData.cMapGroupId,          
          cBuildingId : this.zUI_POI[i].userData.cBuildingId,          
          cMapId : this.zUI_POI[i].userData.cMapId,          
          iNowCount : this.zUI_POI[i].userData.iNowCount,          
        }         

        
        this.zUI_POI_View.push(a);
      }   

      this.ControlsChange();
    },

    SetPOI(zPOI) {           
      for (let j = 0; j < zPOI.length; j++){
        for (let i = 0; i < this.zUI_POI.length; i++) {
          if (zPOI[j].cMapGroupId == this.zUI_POI[i].userData.cMapGroupId && zPOI[j].cBuildingId == this.zUI_POI[i].userData.cBuildingId && zPOI[j].cMapId == this.zUI_POI[i].userData.cMapId) {          
            this.zUI_POI[i].userData.iNowCount= zPOI[j].iCount;
            this.zUI_POI_View[i].iNowCount = zPOI[j].iCount;
          }
        }
      }
      this.ControlsChange();
    },


    IniDevice(zDevice, sMapGroupId, sBuildingId) {         
      for (let i = 0; i < this.zUI_Device.length; i++) {                
        this.zUI_Device.remove(this.zUI_Device[i]);
        this.zUI_Device.splice(i, 1);      
      }

      this.zUI_Device = [];
      this.zUI_Device_View = [];
      
      for (let j = 0; j < zDevice.length; j++){        
        if (zDevice[j].cMapGroupId != sMapGroupId || zDevice[j].cBuildingId != sBuildingId) {
          continue;
        }

        let g = new THREE.SphereGeometry( 1, 1, 1);
        const material = new THREE.MeshBasicMaterial( { color: 0x333333, wireframe: false, transparent: false } );    

        let mesh1 = new THREE.Mesh( g, material );                

        mesh1.position.set((parseFloat(zDevice[j].x) - max_width/2) / rate, 1, ( (max_width/2 - parseFloat(zDevice[j].y))) / rate);
        mesh1.userData.cType = "device";
        mesh1.userData.cSer = parseInt(j);
        mesh1.userData.iMinLOD = 50;
        mesh1.userData.iMaxLOD = 200;        
        mesh1.userData.iUi_x = -100;
        mesh1.userData.iUi_y = -100;
        mesh1.userData.cMac = zDevice[j].cMac;
        
        mesh1.userData.isDel = true;
        mesh1.userData.isSensor = zDevice[j].cType == 'SN';
        mesh1.userData.isOnline = zDevice[j].cState == 'ON';        
        mesh1.userData.cState = zDevice[j].cState;
        mesh1.userData.cDeviceId = zDevice[j].cAPId;
        mesh1.userData.cMapGroupId = zDevice[j].cMapGroupId;
        mesh1.userData.cBuildingId = zDevice[j].cBuildingId;
        mesh1.userData.cMapId = zDevice[j].cMapId;
        mesh1.userData.cNm = zDevice[j].cMapId;        
        mesh1.userData.cSnState = "";

        mesh1.visible = false;          
        scene.add(mesh1);
        this.zUI_Device.push(mesh1);        
      }

      for (let i = 0; i < this.zUI_Device.length; i++) {
        let a = {
          cType : this.zUI_Device[i].userData.cType,
          cSer : this.zUI_Device[i].userData.cSer,
          cMac : this.zUI_Device[i].userData.cMac,
          iMinLOD : this.zUI_Device[i].userData.iMinLOD,
          iMaxLOD : this.zUI_Device[i].userData.iMaxLOD,
          iUi_x : this.zUI_Device[i].userData.iUi_x,
          iUi_y : this.zUI_Device[i].userData.iUi_y,
          cNm : this.zUI_Device[i].userData.cNm,
          isDel : this.zUI_Device[i].userData.isDel,            
          isOnline : this.zUI_Device[i].userData.isOnline,
          cDeviceId : this.zUI_Device[i].userData.cDeviceId,
          cFloorId : this.zUI_Device[i].userData.cFloorId,
          isSensor : this.zUI_Device[i].userData.isSensor,
          cSnState : this.zUI_Device[i].userData.cSnState,
          cSENS_OXYGEN_Lev : this.zUI_Device[i].userData.cSENS_OXYGEN_Lev,
          cSENS_OXYGEN_Data : this.zUI_Device[i].userData.cSENS_OXYGEN_Data,      
          cSENS_CARBON_MONOXIDE_Lev : this.zUI_Device[i].userData.cSENS_CARBON_MONOXIDE_Lev,
          cSENS_CARBON_MONOXIDE_Data : this.zUI_Device[i].userData.cSENS_CARBON_MONOXIDE_Data,      
          cSENS_HYDROGEN_Lev : this.zUI_Device[i].userData.cSENS_HYDROGEN_Lev,
          cSENS_HYDROGEN_Data : this.zUI_Device[i].userData.cSENS_HYDROGEN_Data,      
          cSENS_METHAN_Lev : this.zUI_Device[i].userData.cSENS_METHAN_Lev,
          cSENS_METHAN_Data : this.zUI_Device[i].userData.cSENS_METHAN_Data, 
        }
        this.zUI_Device_View.push(a);
      }
      
      this.$forceUpdate();
      this.ControlsChange();
    },    



    SetDevice(zDevice) {
      if (this.zUI_Device == null) return;

      for (let i = 0; i < this.zUI_Device.length; i++) {
        this.zUI_Device[i].userData.isDel = true;
        for (let j = 0; j < zDevice.length; j++){          
          // console.log(zDevice[j].cState);
          if (this.zUI_Device[i].userData.cMac == zDevice[j].cMac) {
            this.zUI_Device[i].userData.isOnline = zDevice[j].cState == 'ON';
            this.zUI_Device[i].userData.isDel = false;

            this.zUI_Device_View[i].isOnline = zDevice[j].cState == 'ON';
            this.zUI_Device_View[i].isDel = false;
            break;
          }          
        }
      }      
    },

    SetSensor(zSensor) {      
      if (this.zUI_Device == null) return;

      for (let i = 0; i < this.zUI_Device.length; i++) {                
        for (let j = 0; j < zSensor.length; j++){       
          if (this.zUI_Device[i].userData.cMac == zSensor[j].cSensorId) {                        
            this.zUI_Device[i].userData.isOnline = zSensor[j].cState == 'ON';
            this.zUI_Device[i].userData.cSnState = zSensor[j].cEMERGENCY_Lev;            
            this.zUI_Device[i].userData.cSENS_OXYGEN_Lev = zSensor[j].cSENS_OXYGEN_Lev;      
            this.zUI_Device[i].userData.cSENS_OXYGEN_Data = zSensor[j].cSENS_OXYGEN_Data;      
            this.zUI_Device[i].userData.cSENS_CARBON_MONOXIDE_Lev = zSensor[j].cSENS_CARBON_MONOXIDE_Lev;      
            this.zUI_Device[i].userData.cSENS_CARBON_MONOXIDE_Data = zSensor[j].cSENS_CARBON_MONOXIDE_Data;      
            this.zUI_Device[i].userData.cSENS_HYDROGEN_Lev = zSensor[j].cSENS_HYDROGEN_Lev;      
            this.zUI_Device[i].userData.cSENS_HYDROGEN_Data = zSensor[j].cSENS_HYDROGEN_Data;      
            this.zUI_Device[i].userData.cSENS_METHAN_Lev = zSensor[j].cSENS_METHAN_Lev;      
            this.zUI_Device[i].userData.cSENS_METHAN_Data = zSensor[j].cSENS_METHAN_Data;      
            this.zUI_Device[i].userData.isDel = false;

            this.zUI_Device_View[i].isOnline = zSensor[j].cState == 'ON';
            this.zUI_Device_View[i].cSnState = zSensor[j].cEMERGENCY_Lev;                             
            this.zUI_Device_View[i].cSENS_OXYGEN_Lev = zSensor[j].cSENS_OXYGEN_Lev;      
            this.zUI_Device_View[i].cSENS_OXYGEN_Data = zSensor[j].cSENS_OXYGEN_Data;      
            this.zUI_Device_View[i].cSENS_CARBON_MONOXIDE_Lev = zSensor[j].cSENS_CARBON_MONOXIDE_Lev;      
            this.zUI_Device_View[i].cSENS_CARBON_MONOXIDE_Data = zSensor[j].cSENS_CARBON_MONOXIDE_Data;      
            this.zUI_Device_View[i].cSENS_HYDROGEN_Lev = zSensor[j].cSENS_HYDROGEN_Lev;      
            this.zUI_Device_View[i].cSENS_HYDROGEN_Data = zSensor[j].cSENS_HYDROGEN_Data;      
            this.zUI_Device_View[i].cSENS_METHAN_Lev = zSensor[j].cSENS_METHAN_Lev;      
            this.zUI_Device_View[i].cSENS_METHAN_Data = zSensor[j].cSENS_METHAN_Data;      
            this.zUI_Device_View[i].isDel = false;           
            break;
          }          
        }
      }      
    },

    SetUser(zUser) {      
      console.log("SetUser", zUser);
      if (zUser == null) return;

      for (let i = 0; i < this.zUI_User.length; i++) {        
        this.zUI_User[i].userData.isDel = true;        
        for (let j = 0; j < zUser.length; j++){          
          if (this.zUI_User[i].userData.cUserId == zUser[j].cUserId) {                        
            this.zUI_User[i].userData.isDel = false;                  
            break;
          }          
        }
      }

      for (let i = 0; i < this.zUI_User.length; i++) {        
        if (this.zUI_User[i].userData.isDel) {
          scene.remove(this.zUI_User[i]);
          this.zUI_User.splice(i, 1);
          this.zUI_User_View.splice(i, 1);
        }
      }

      var isFind = false;
      var isFindDevice = false;
      for (let j = 0; j < zUser.length; j++){          
        zUser[j].x = 0;
        zUser[j].y = 0;
        zUser[j].z = 0;

        isFindDevice = false;
        for (let i = 0; i < this.zUI_Device.length; i++) {
          if (this.zUI_Device[i].userData.cMac == zUser[j].cMac) {
            zUser[j].x = this.zUI_Device[i].position.x + this.getRandom();
            zUser[j].y = this.zUI_Device[i].position.y;
            zUser[j].z = this.zUI_Device[i].position.z + this.getRandom();            
            isFindDevice = true;
            break;
          }
        }

        if (!isFindDevice) {
          continue;
        }
        
        isFind = false;

        this.isChange = true;
        setTimeout(() => {
          this.isChange = false;
        }, 10);
        
        for (let i = 0; i < this.zUI_User.length; i++) {        
          if (this.zUI_User[i].userData.cUserId == zUser[j].cUserId) {
            isFind = true;            
            if (this.zUI_User[i].userData.cApId != zUser[j].cApId || this.zUI_User[i].userData.isSOS != zUser[j].cSOS == 'Y') {
              console.log(">>>>>>>>>>>>>>>....Find...", zUser[j].cApId, this.zUI_User[i].userData.isSOS, zUser[j].cSOS == 'Y', this.zUI_User[i].userData.isSOS != zUser[j].cSOS == 'Y' );
              this.zUI_User[i].position.set(zUser[j].x, zUser[j].y, zUser[j].z);              
            }

            this.zUI_User[i].userData.cApId = zUser[j].cApId;
            this.zUI_User[i].userData.isSOS = zUser[j].cSOS == 'Y';
            this.zUI_User[i].userData.cFloorId = zUser[j].cFloorId;            
            this.zUI_User_View[i].isSOS = zUser[j].cSOS == 'Y';
            
            this.$forceUpdate();
            break;
          }          
        }

        if (!isFind) {         
          let g = new THREE.SphereGeometry( 1, 1, 1);
          const material = new THREE.MeshBasicMaterial( { color: 0x333333, wireframe: false, transparent: false } );    
          let mesh1 = new THREE.Mesh( g, material );        
          mesh1.position.set(zUser[j].x, zUser[j].y, zUser[j].z);
          mesh1.userData.iMinLOD = 0;
          mesh1.userData.iMaxLOD = 1000;        
          mesh1.userData.iUi_x = zUser[j].x;
          mesh1.userData.iUi_y = zUser[j].y;
          mesh1.userData.cUserId = zUser[j].cUserId;
          mesh1.userData.cUserNm = zUser[j].cUserNm;          
          mesh1.userData.cCorpNm = zUser[j].cCorpNm;
          mesh1.userData.cPartNm = zUser[j].cPartNm;          
          mesh1.userData.isSOS = zUser[j].cSOS == 'Y';
          mesh1.userData.cApId = zUser[j].cApId;
          mesh1.userData.cFloorId = zUser[j].cFloorId;

          
          mesh1.visible = false;          
          scene.add(mesh1);

          this.zUI_User.push(mesh1);
          let a = {
            cType : mesh1.userData.cType,
            cSer : mesh1.userData.cSer,
            iMinLOD : mesh1.userData.iMinLOD,
            iMaxLOD : mesh1.userData.iMaxLOD,
            iUi_x : mesh1.userData.iUi_x,
            iUi_y : mesh1.userData.iUi_y,
            cUserId : mesh1.userData.cUserId,
            cUserNm : mesh1.userData.cUserNm,            
            cCorpCaption : mesh1.userData.cCorpCaption,
            cCorpNm : mesh1.userData.cCorpNm,
            cPartNm : mesh1.userData.cPartNm,            
            isSOS : mesh1.userData.isSOS,
            cApId : mesh1.userData.cApId,          
          }
          this.zUI_User_View.push(a);        
        }
      }     

      this.$forceUpdate();
      this.ControlsChange();
    },

    
    SetSOS(cUserId, isSOS) {      
      console.log(">>>>>>>>>>>>>SetSOS", cUserId, isSOS);
      for (let i = 0; i < this.zUI_User.length; i++) {                
        if (this.zUI_User[i].userData.cUserId == cUserId) {          
          this.zUI_User[i].userData.isSOS = isSOS;
          this.zUI_User_View[i].isSOS = isSOS;
          break;
        }          
      }

      this.$forceUpdate();  
      this.ControlsChange();
    },

    getRandom(){
      let itmp = Math.floor(Math.random() * 800) - 100;
      if (itmp > 0) {
        itmp = itmp + 100;
      } else {
        itmp = itmp - 100;
      }
      return itmp/200;
    },

    onClick(event){
      const rect = event.target.getBoundingClientRect();

      const clientX = event.clientX - rect.left;
      const clientY = event.clientY - rect.top;

      var idx = -1;
      var distance = 999999;
      var sType = "";
      var sData = "";

      var point_width = 60;
      var point_top = 70;
      var point_bottom = 0;      

      for (let i = 0; i < this.zUI_POI_View.length; i++) {        
        this.zUI_POI_View[i].isSel = false;
      }

      this.ControlsChange();
      for (let i = 0; i < this.zUI_POI.length; i++) {                
        if ((clientX > this.zUI_POI[i].userData.iUi_x - point_width) && (clientX < this.zUI_POI[i].userData.iUi_x + point_width)){
          if ((clientY > this.zUI_POI[i].userData.iUi_y - point_top) && (clientY < this.zUI_POI[i].userData.iUi_y + point_bottom)){
            let now_dis = this.getDistance(this.zUI_POI[i].userData.iUi_x, this.zUI_POI[i].userData.iUi_y - 40, clientX, clientY);              
            if (distance > now_dis){                
              distance = now_dis;
              sType = "POI";
              idx = i;
              sData = this.zUI_POI[i].userData.cPOIId;                                    
            }
          } 
        }
      }      

      if (idx != -1) {                
        this.zUI_POI_View[idx].isSel = true;
        this.$emit("onClick", sType, sData);
      }
    },

    ControlsChange(){      
      if (this.isUpdateWindow) {
        this.resetTimeOut();    
      } else {
        this.isUpdateWindow = true;
        requestAnimationFrame(this.animate);
        this.resetTimeOut();
      }
    },

    getScreenPositionCSS2(vertices_){    
      return this.toScreenXYCSS(vertices_, camera);      
    },

    
    toScreenXYCSS(v_, camera_) {
      var p = new THREE.Vector3(v_.x, v_.y, v_.z);
      var vector = p.project(camera_);

      vector.x = Math.round((vector.x + 1) / 2 * this.windowWidth);
      vector.y = Math.round(-(vector.y - 1) / 2 * this.windowHeight);

      return { x: vector.x, y: vector.y };
    },

    getDistance( ax, ay, zx, zy ){
      var dis_x = ax - zx;
      var dix_y = ay - zy;
      var dist = Math.sqrt( Math.abs( dis_x * dis_x ) + Math.abs( dix_y * dix_y ) );
      return dist;
    },

    Reset2DPosition(zData, zDataBuffer) {
      for (let i = 0; i < zDataBuffer.length; i++) {        
        var pos = new THREE.Vector3(zDataBuffer[i].position.x, zDataBuffer[i].position.y, zDataBuffer[i].position.z);
        var vector = pos.project(camera);
        vector.x = Math.round((vector.x) * this.windowWidth/2);
        vector.y = Math.round((vector.y) * this.windowHeight/2);
        if (zData[i] != null) {
        zData[i].position.set( vector.x, vector.y, 1 );                 
        }
      }
    },

    // InitHome(){
    //   var a = localStorage.getItem("DefaultHomePos.Camera.x");
    //   if (a == null) {
    //     this.goInitHome();
    //   } else {
    //     this.HomePos.Camera.x = localStorage.getItem("DefaultHomePos.Camera.x");
    //     this.HomePos.Camera.y = localStorage.getItem("DefaultHomePos.Camera.y");
    //     this.HomePos.Camera.z = localStorage.getItem("DefaultHomePos.Camera.z");
    //     this.HomePos.Ctrl.x = localStorage.getItem("DefaultHomePos.Ctrl.x");
    //     this.HomePos.Ctrl.y = localStorage.getItem("DefaultHomePos.Ctrl.y");
    //     this.HomePos.Ctrl.z = localStorage.getItem("DefaultHomePos.Ctrl.z");
    //   }
    // },
    
    // goSetHome(){
    //   this.HomePos.Camera.x = camera.position.x;
    //   this.HomePos.Camera.y = camera.position.y;
    //   this.HomePos.Camera.z = camera.position.z;      
    //   this.HomePos.Ctrl.x = controls.target.x;
    //   this.HomePos.Ctrl.y = controls.target.y;
    //   this.HomePos.Ctrl.z = controls.target.z;    

    //   console.log(this.HomePos);

    //   localStorage.setItem("DefaultHomePos.Camera.x", this.HomePos.Camera.x);
    //   localStorage.setItem("DefaultHomePos.Camera.y", this.HomePos.Camera.y);
    //   localStorage.setItem("DefaultHomePos.Camera.z", this.HomePos.Camera.z);

    //   localStorage.setItem("DefaultHomePos.Ctrl.x", this.HomePos.Ctrl.x);
    //   localStorage.setItem("DefaultHomePos.Ctrl.y", this.HomePos.Ctrl.y);
    //   localStorage.setItem("DefaultHomePos.Ctrl.z", this.HomePos.Ctrl.z);
    // },

    // goInitHome(){
    //   this.HomePos.Camera.x = DefaultHomePos.Camera.x;
    //   this.HomePos.Camera.y = DefaultHomePos.Camera.y;
    //   this.HomePos.Camera.z = DefaultHomePos.Camera.z;      
    //   this.HomePos.Ctrl.x = DefaultHomePos.Ctrl.x;
    //   this.HomePos.Ctrl.y = DefaultHomePos.Ctrl.y;
    //   this.HomePos.Ctrl.z = DefaultHomePos.Ctrl.z;            


    //   localStorage.setItem("DefaultHomePos.Camera.x", this.HomePos.Camera.x);
    //   localStorage.setItem("DefaultHomePos.Camera.y", this.HomePos.Camera.y);
    //   localStorage.setItem("DefaultHomePos.Camera.z", this.HomePos.Camera.z);

    //   localStorage.setItem("DefaultHomePos.Ctrl.x", this.HomePos.Ctrl.x);
    //   localStorage.setItem("DefaultHomePos.Ctrl.y", this.HomePos.Ctrl.y);
    //   localStorage.setItem("DefaultHomePos.Ctrl.z", this.HomePos.Ctrl.z);
    // },

    goHome(){          
      // controls.reset();

      camera.position.set(camera.position.x,camera.position.y,camera.position.z+20);
      controls.target.set( 0, 0, 0 );
      TWEEN.removeAll();
      new TWEEN.Tween( camera.position )           
      // .to( { x:0, y:9, z:0 }, 1000) 
      .to( { x: this.HomePos.Camera.x, y:this.HomePos.Camera.y, z:this.HomePos.Camera.z}, 1000) 
      .easing( TWEEN.Easing.Exponential.InOut )
      .start();
      new TWEEN.Tween( controls.target )           
      // .to( { x:0, y:9, z:0 }, 1000) 
      .to( { x:this.HomePos.Ctrl.x, y:this.HomePos.Ctrl.y, z:this.HomePos.Ctrl.z }, 1000) 
      .easing( TWEEN.Easing.Exponential.InOut )
      .start();          
    },



    getHighlight(org, find) {
      var mystring = org;
      let regex = new RegExp(find, 'gi');
      let a = mystring.replace(regex, function(match) {
          return "<mark>" + match + "</mark>";
      });
      return a;
    },

    MoveToCenter3D(x, y, z, iDistanceZoom) {
      let dir = new THREE.Vector3();
      let vector = new THREE.Vector3();
      let cameraDir = camera.getWorldDirection(vector)
      dir.set(
        -(cameraDir.x) * iDistanceZoom + x,
        -(cameraDir.y) * iDistanceZoom + z,
        -(cameraDir.z) * iDistanceZoom + y,
      );
      let cameraP = new THREE.Vector3(dir.x, dir.y, dir.z);
      let ctrl = new THREE.Vector3(x, z, y);
      this.transform(cameraP, ctrl, camera, 1000 );
    },

    transform( pos, ctrl, object, duration ) {
			TWEEN.removeAll();

      new TWEEN.Tween( controls.target ) // controler (상하좌우이동)
        .to( { x: ctrl.x, y: ctrl.y, z: ctrl.z }, duration) // 목표지점
        .easing( TWEEN.Easing.Exponential.InOut ) // 자연스러운 이동모션
        .start(); // 즉시시작

      new TWEEN.Tween( object.position ) // 카메라 위치
        .to( { x: pos.x, y: pos.y, z: pos.z }, duration )
        .easing( TWEEN.Easing.Exponential.InOut )
        .start();
      
      new TWEEN.Tween( this )
        .to( {}, duration )
        .onUpdate( this.render )
        .start();
		}, 
    render() {
      renderer.render(scene, camera);
    },


    goMap(data) {
      for (let i = 0; i < this.zUI_POI.length; i++) {        
        this.zUI_POI_View[i].isSel = false;
        if (this.zUI_POI[i].userData.cPOIId == data) {
          this.zUI_POI_View[i].isSel = true;
          this.MoveToCenter3D(this.zUI_POI[i].position.x, this.zUI_POI[i].position.z, this.zUI_POI[i].position.y, 100);
          this.ControlsChange();
        }
      }
    },

    
  }
}



</script>

<style>
  .ViewState {
    z-index: 1;
    position: absolute;
    cursor: pointer;    
    left: 10px;    
    top: 40px;    
  }

  #ui {                
    position:absolute;
    z-index: 2;      
    /* background: #FF000020; */    
  }

  .poi_ui {
    -webkit-user-select: none; /* Chrome, Safari */
    -moz-user-select: none;    /* Firefox */
    -ms-user-select: none;     /* Internet Explorer/Edge */
    user-select: none;      

    -webkit-animation: moveSlideshow 2s ease-in-out 1; 
    -moz-animation: moveSlideshow 2s ease-in-out 1;
    -o-animation: moveSlideshow 2s ease-in-out 1;
  }
  
  .label_css_ui{
    font-size: 14px;
    width: 200px;
    
    opacity: 1;  
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -user-select: none;

    font-size : 22px;  
    font-weight:900;    
    text-shadow:
    -1.3px -1.3px 0 #FFFFFF,
    1.3px -1.3px 0 #FFFFFF,
    -1.3px 1.3px 0 #FFFFFF,
    1.3px 1.3px 0 #FFFFFF;  

    fill: #222222;

    -webkit-animation: moveSlideshow 2s ease-in-out 1; 
    -moz-animation: moveSlideshow 2s ease-in-out 1;
    -o-animation: moveSlideshow 2s ease-in-out 1;
  }

  @keyframes moveSlideshow {
    0% { 
      opacity: 0;  
    }
    100% { 
      opacity: 1;  
    }
  }

  .no-select {
    -webkit-user-select: none; /* Chrome, Safari */
    -moz-user-select: none;    /* Firefox */
    -ms-user-select: none;     /* Internet Explorer/Edge */
    user-select: none;         /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  }


  .animated-image {
    border-radius: 10px; /* 선택 사항: 모서리를 둥글게 */
    animation: boxShadowPulse 2s infinite ease-in-out; /* 애니메이션 추가 */
}

  @keyframes boxShadowPulse {
    0% {
        opacity: 1;
    }
    60% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }

  .box_ap_FontQty {   
    color: white;
    font-size : 25px;  
    font-weight: 400;  
    padding-bottom: 40px;
    padding-left: 12px;
    padding-right: 12px;
    /* background: #D32F2F; iron28 */ 
    background: #3333FF66;
    width:100px;    
    
    height : 40px;  
    border-radius: 50px;
    border: 2px solid white;
    box-shadow: 0 0 8px #555555;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -user-select: none;
    animation: heartbeat2 2s infinite;
  }

  
@keyframes heartbeat2 {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(1.30);
            transform: scale(1.30);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}



.box_ap_state_on {     
  background: #22ff00AA;
  
  height : 20px;  
  width : 20px;  
  border-radius: 50px;
  border: 2px solid white;
  box-shadow: 0 0 8px #555555;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -user-select: none;  

  -webkit-animation: alramshow 1.5s ease-in-out infinite alternate; 
  -moz-animation: alramshow 1.5s ease-in-out infinite alternate;
  -o-animation: alramshow 1.5s ease-in-out infinite alternate;
}

.box_ap_state_off {     
  background: #ff0000AA;
  
  height : 20px;  
  width : 20px;  
  border-radius: 50px;
  border: 2px solid white;
  box-shadow: 0 0 8px #555555;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -user-select: none;

  -webkit-animation: alramshow 1.5s ease-in-out infinite alternate; 
  -moz-animation: alramshow 1.5s ease-in-out infinite alternate;
  -o-animation: alramshow 1.5s ease-in-out infinite alternate;
}
.box_ap_state_del {     
  background: #000000AA;
  
  height : 20px;  
  width : 20px;  
  border-radius: 50px;
  border: 2px solid white;
  box-shadow: 0 0 8px #555555;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -user-select: none;

  -webkit-animation: alramshow 1.5s ease-in-out infinite alternate; 
  -moz-animation: alramshow 1.5s ease-in-out infinite alternate;
  -o-animation: alramshow 1.5s ease-in-out infinite alternate;
}



.box_sn_state_on {     
  background: #22ff00cc;
  
  height : 20px;  
  width : 20px;  
  border-radius: 5px;
  border: 2px solid white;
  box-shadow: 0 0 80px #555555;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -user-select: none;  

  -webkit-animation: alramshow 1.5s ease-in-out infinite alternate; 
  -moz-animation: alramshow 1.5s ease-in-out infinite alternate;
  -o-animation: alramshow 1.5s ease-in-out infinite alternate;
}

.box_sn_state_off {     
  background: #ff0000AA;
  
  height : 20px;  
  width : 20px;  
  border-radius: 5px;
  border: 2px solid rgb(77, 30, 30);
  box-shadow: 0 0 8px #555555;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -user-select: none;

  -webkit-animation: alramshow 1.5s ease-in-out infinite alternate; 
  -moz-animation: alramshow 1.5s ease-in-out infinite alternate;
  -o-animation: alramshow 1.5s ease-in-out infinite alternate;
}
.box_sn_state_del {     
  background: #000000AA;
  
  height : 20px;  
  width : 20px;  
  border-radius: 5px;
  border: 2px solid white;
  box-shadow: 0 0 8px #555555;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -user-select: none;

  -webkit-animation: alramshow 1.5s ease-in-out infinite alternate; 
  -moz-animation: alramshow 1.5s ease-in-out infinite alternate;
  -o-animation: alramshow 1.5s ease-in-out infinite alternate;
}


@keyframes alramshow {
  0% { 
    opacity: 0.3;  
  }
  100% { 
    opacity: 1;  
  }
}


.User_Box {
  position : relative;
  left : -17px;
  top : -68px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -user-select: none;
}

.GAS_BOX_Danger {
  position : relative;
  left : -42px;
  top : -42px;
  background: #FFFFFFAA;  
  height : 100px;  
  width : 100px;  
  border-radius: 100px;
  border: 3px solid FF000088;  
  box-shadow: 0 0 80px #e43a3aAA;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -user-select: none;

  -webkit-animation: scale-in-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
  animation: scale-in-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
}
.ASSET_BOX_Danger {
  position : relative;
  left : -33px;
  top : -71px;
  background: #FFFFFFAA;  
  height : 100px;  
  width : 100px;  
  border-radius: 100px;
  border: 3px solid FF000088;  
  box-shadow: 0 0 80px #e43a3aAA;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -user-select: none;

  -webkit-animation: scale-in-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
  animation: scale-in-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
}

.SOS_BOX {
  position : relative;
  left : -25px;
  top : -116px;
  background: #FFFFFFAA;  
  height : 50px;  
  width : 50px;  
  border-radius: 50px;
  border: 3px solid rgb(211, 44, 44);
  box-shadow: 0 0 25px #e43a3a;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -user-select: none;

  -webkit-animation: scale-in-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
  animation: scale-in-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
}


@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }  
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }  
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.1;
  }  
}


p > ::v-deep mark {
  font-weight : bold; color:#ff0000; background: #00000000; 
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.custom-tooltip {
  background-color: #000000; /* 원하는 색상 */
  color: #ffffff; /* 텍스트 색상 */
}

</style>

