<template>
  <div v-resize="onResize">
    <div style="background:#ECEFF1;">
      <div style="background:#FFFFFF; height:35px;" class="d-flex">              
        <ul>
          <li class="tab ml-n3 pt-1 pl-3 pr-3" :style="isMapMode?'background: #F9F9F9; border-bottom: none;':''" @click="changeTab(true)">MAP</li>
          <li class="tab ml-0 pt-1 pl-3 pr-3" :style="!isMapMode?'background: #F9F9F9; border-bottom: none;':''" @click="changeTab(false)">GRAPH</li>
        </ul>
        
        <v-spacer></v-spacer>          
        <div class="d-flex" style="align-items : center;" >          
          <div >
            <v-icon v-if="bNotiSound" big class="mt-0" @click="goNotiSound">mdi-volume-high</v-icon>
            <v-icon v-else big class="mt-0" @click="goNotiSound">mdi-volume-off</v-icon>
          </div>
          <div v-if="iAlertCount == 0" class="d-flex ml-3" style="align-items : center;" >
            <div class="grey--text caption">호출</div>
            <div class="grey--text headline font-weight-black ml-2 mr-4">{{iAlertCount}}</div>            
          </div>
          <div v-else class="d-flex ml-3" style="align-items : center;" @click="goSOS()">
            <div class="red--text caption">호출</div>
            <div class="red--text headline font-weight-black ml-2 mr-4 heartbeat2">{{iAlertCount}}</div>            
          </div>
        </div>
      </div>      
      <v-divider></v-divider>     
    </div>
      
          
    <div      
      ref="imageContainer"      
      class="image-container"
      @wheel="onWheel"
      @mousedown="onMouseDown"
      @mousemove="onMouseMove"
      @mouseup="onMouseUp"
      @mouseleave="onMouseUp" >      
      <img
        ref="image"
        src="/map/main_back.svg"
        :style="imageStyle"
        alt="Zoomable"/>
      <svg
        class="on-show"
        ref="svgElement"
        style="position: absolute; width:auto;height:auto; left:0px; top:0px; transition: opacity 0.5s ease, transform 0.5s ease;"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 2048 1024"
        width="2048"
        height="1024">  
        <foreignObject 
          v-for="(item, j) in ZMap" :key="j+1000"
          :x="item.x * scale + translateX - 45 + 80" 
          :y="item.y * scale + translateY - 45" 
          width="200" 
          height="90">      
          <div v-if="item.iQty != 0" class="text-center floor_num_qty" @click="onGoFloor(item)" style="cursor: pointer;">
            {{item.iQty}}
          </div>
        </foreignObject>
  
        <foreignObject 
          v-for="(item, i) in ZMap" :key="i"
          :x="item.x * scale + translateX - 45" 
          :y="item.y * scale + translateY - 45" 
          width="90" 
          height="90">      
          <div class="text-center floor_num" @click="onGoFloor(item)">
            {{item.name}}
          </div>
        </foreignObject>
      </svg>    
    </div>

    <div v-if="is3d" style="z-index:9; position:absolute; top:36px; left:0px; width : calc(100vw - 60px); height : calc(100vh - 118px); overflow: hidden;">
      <div id="mapWindow" v-resize="onResize2" style="width : 100%; height: 100%;">
        <MapWindowView :windowWidth="mapWidth" :windowHeight="mapHeight" ref="MapWindowViewRef" @onClick="onPOIClick"/>
      </div>
    </div>

    <div style="z-index:10; width:310px; position:absolute; top:36px; right: 17px;" v-if="isSideBar">
      <v-card width="310" height="calc(100vh - 135px)" class="ma-2 pa-0" color="#FFFFFF" elevation="3" style="border-radius: 8px; overflow: hidden;">            
        <div :class="isWindowsEvent?'box_map_left':'box_map_left2'">
          <div class="d-flex mt-n1 mb-n4">
            <v-text-field
              v-model="cNowSearchKeyWord"
              class="ml-0 mt-1 mr-0"  
              label="Search"
              placeholder="검색어를 입력하세요."
              persistent-placeholder                                    
              v-on:keyup.enter="onSearch()"
            ></v-text-field>
            <v-btn  text icon class="ml-n9 mt-3" @click="onSearch()"><v-icon>search</v-icon></v-btn> 
          </div>

          <v-treeview     
            :active="selection"                
            :items="items"
            item-key="id"
            activatable                                    
            hoverable
            color="#263238"
            dense  
            :open.sync="open"
            @update:active="onUpdate"
            return-object>                      
            <template v-slot:append="{ item }">                      
              <div v-if="item.cMapGroupId != '00' && item.cBuildingId == '00' && item.cMapId == '00'" style="width:100px; text-align: right;">
                <div v-if="bCheckMapRole(item.cMapGroupId, item.cBuildingId, item.cMapId)" class="h3 font-weight-black">{{item.iCount}}/{{item.iCountOut}}</div>
              </div>
              <div v-else style="width:50px; text-align: right;">
                <div v-if="bCheckMapRole(item.cMapGroupId, item.cBuildingId, item.cMapId)" class="h3 font-weight-black">{{item.iCount}}</div>                 
              </div>
            </template>
            <template v-slot:label="{ item }">                                              
              <div :class="bCheckMapRole(item.cMapGroupId, item.cBuildingId, item.cMapId)?'body':'grey--text'">{{item.name}}</div>                 
            </template>
          </v-treeview>              
        </div>
        <div>  
          <v-divider class="mx-0"></v-divider>
          <v-card-title class="mt-n2 mb-n6">              
            <p class="caption grey--text">비인가자 정보</p>
            <v-spacer></v-spacer>
            <v-btn class="mt-n4 mr-n2" icon dense @click="CheckAllEV03()"><v-icon small>mdi-check-all</v-icon></v-btn>                    
            <v-btn class="mt-n4 mr-n3" icon dense @click="isWindowsEvent = !isWindowsEvent"><v-icon small>mdi-more</v-icon></v-btn>                    
          </v-card-title>
          
          <v-divider class="mx-0"></v-divider>
          <div style="background-color:#26323810; width:100%;" v-if="isWindowsEvent">
            <v-virtual-scroll
              :bench="0"
              :items="zLog"
              height="220"
              item-height="64">
              <template v-slot:default="{ item }">
                <v-list-item two-line class="ml-2 mt-0 mb-n3">
                  <v-list-item-icon class="mt-5 mb-0 ml-n4 mr-2" ><v-icon :class="getFontColor(item.cChecked)" @click="SetEventCheckFunc(item)">mdi-alert-circle</v-icon></v-list-item-icon>
                  <v-list-item-content>                    
                    <v-list-item-title :class="getDateCSS(item.cChecked)" @click="SetEventCheckFunc(item)">{{item.cCreateDateTime}}</v-list-item-title>
                    <div class="check-button" v-show="item.cChecked=='N'">
                      <v-btn  icon class="mt-n3 mb-n2" @click="SetEventCheckFunc(item)"><v-icon small>mdi-check</v-icon></v-btn>
                    </div>                    
                    <v-list-item-subtitle @click="SetEventCheckFunc(item)"><p :class="getCSS(item.cChecked)">{{item.cMapFullNm + ' ' + item.cUserNm + '('+ item.cCorpNm+ ')'}}</p></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </div>                  
        </div>
      </v-card>
    </div>  

    <div class="alert_box" v-show="iAlertCount != 0 && isShowAlert" id="blinking" @click="goSOS()">      
      <v-icon large color="white" class="mr-3">call</v-icon>비상호출 상황이 발생하였습니다!
    </div>   

    <div class="event_box" v-show="isAlertEvent != 0 && isShowAlert" id="blinking" @click="showDevice('EV03')">
      <v-icon large color="white" class="mr-3">mdi-alert</v-icon>비인가자가 접근하였습니다!
    </div>   

    <AlertShowSel ref="AlertShowSelRef" @onSelect="onSelect" />    
    <SetSOS ref="SetSOSRef" @onSetSOS="onSetSOS" />    
    <SetEventCheck ref="SetEventCheckRef" @onSetEventCheck="onSetEventCheck" />  
    

    <div class="box_sel_info_floor" v-if="isSideBar && sNowMapId != '00' && sNowMapId != '' ">
      <div class="justify-space-between">
        <v-card class="elevation-3"  style="width:400px; height:100px; font-size:14px; padding:10px; border-radius: 8px;">
          <div class="d-flex">구역 : {{NowSelMapInfo.cMapNm}} 
            <v-spacer></v-spacer>
            <v-btn class="mt-n2 mb-n2 mr-n1" icon dense @click="BackMap()"><v-icon >mdi-arrow-left</v-icon></v-btn>
          </div>
          <div class="d-flex justify-space-between mt-2">
            <div>총작업인원</div>
            <div class="d-flex"><div style="font-size:20px; margin-top:-5px;margin-right:5px;">{{NowSelMapInfo.iCount}}</div> 명</div>
          </div> 
          <div class="d-flex justify-space-between mt-2">
            <div>직원 {{NowSelMapInfo.iSKMRCount}}명</div>
            <div>작업자 {{NowSelMapInfo.iOtherCount}}명</div>
          </div> 
        </v-card>           
        <div class="mt-2 elevation-3" style="overflow:hidden;  border-radius: 8px; ">
          <v-data-table             
            :items-per-page="-1" 
            fixed-header             
            :headers="headers2" 
            hide-default-footer
            :items="NowSelMapInfo.zUserInfo"  
            width="400"          
            :height="200">            
            <template v-slot:item.cType="{ item }">                
              <v-chip label small v-if ="item.cType == '비인가진입'" dark color="red lighten-2" >{{item.cType}}</v-chip>
              <v-chip label small v-if ="item.cType == '공정'" color="blue lighten-2" dark >{{item.cType}}/{{item.cReqMessage}}</v-chip>
              <v-chip label small v-if ="item.cType == '일반'" color="green lighten-3" dark >{{item.cType}}/{{item.cReqMessage}}</v-chip>                                    
              <v-chip label small v-if ="item.cType == 'SKMR'" color="grey lighten-3" >작업</v-chip>       
            </template>

            <template v-slot:no-data>
              <p>No data available.</p>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    
    <div class="box_sel_info_building" v-if="isSideBar && sNowMapId == '00' && sNowBuildingId == '00' && sNowMapGroupId != '00'">            
      <div class="justify-space-between">
        <v-card class="elevation-3" style="width:500px; height:100px; font-size:14px; padding:10px; border-radius: 8px;">
          <!-- <div><v-btn class="mt-n2 mb-n2 mr-1" icon dense @click="BackMap()" dark><v-icon >mdi-arrow-left</v-icon></v-btn>구역 : {{NowSelMapGroupInfo.cMapNm}}</div> -->
          <div class="d-flex">구역 : {{NowSelMapGroupInfo.cMapNm}} 
            <v-spacer></v-spacer>
            <v-btn class="mt-n2 mb-n2 mr-n1" icon dense @click="BackMap()"><v-icon >mdi-arrow-left</v-icon></v-btn>
          </div>
          <div class="d-flex justify-space-between mt-2">
            <div class="d-flex">총작업인원 <div v-if="isPOS_Near">(건물 밖 인원)</div></div>
            <div class="d-flex"><div style="font-size:20px; margin-top:-5px;margin-right:5px;">{{NowSelMapGroupInfo.iCount}}</div> 명</div>
          </div> 
          <div class="d-flex justify-space-between mt-2">
            <div>직원 {{NowSelMapGroupInfo.iSKMRCount}}명</div>
            <div>작업자 {{NowSelMapGroupInfo.iOtherCount}}명</div>
          </div> 
        </v-card>                              
        <div class="mt-2 elevation-3" style="overflow:hidden;  border-radius: 8px; ">
          <v-data-table               
            :items-per-page="-1" 
            fixed-header             
            :headers="headers4" 
            hide-default-footer
            :items="NowSelMapGroupInfo.zUserInfo"  
            width="400"          
            :height="200">            
            <template v-slot:item.cType="{ item }">                
              <v-chip label small v-if ="item.cType == '비인가진입'" dark color="red lighten-2" >{{item.cType}}</v-chip>
              <v-chip label small v-if ="item.cType == '공정'" color="blue lighten-2" dark >{{item.cType}}/{{item.cReqMessage}}</v-chip>
              <v-chip label small v-if ="item.cType == '일반'" color="green lighten-3" dark >{{item.cType}}/{{item.cReqMessage}}</v-chip>                                    
              <v-chip label small v-if ="item.cType == 'SKMR'" color="grey lighten-3" >작업</v-chip>       
            </template>

            <template v-slot:no-data>
              <p>No data available.</p>
            </template>
          </v-data-table>
        </div>
      </div>           
    </div>

    <div class="box_sel_info_building" v-if="isSideBar && sNowMapId == '00' && sNowBuildingId != '00'">
      <div class="justify-space-between">
        <v-card class="elevation-3" style="width:500px; height:100px; font-size:14px; padding:10px; border-radius: 8px;">
          <!-- <div><v-btn class="mt-n2 mb-n2 mr-1" icon dense @click="BackMap()" dark><v-icon >mdi-arrow-left</v-icon></v-btn>구역 : {{NowSelBuildingInfo.cMapNm}}</div> -->
          <div class="d-flex">구역 : {{NowSelBuildingInfo.cMapNm}} 
            <v-spacer></v-spacer>
            <v-btn class="mt-n2 mb-n2 mr-n1" icon dense @click="BackMap()"><v-icon >mdi-arrow-left</v-icon></v-btn>
          </div>
          <div class="d-flex justify-space-between mt-2">
            <div >총작업인원</div>
            <div class="d-flex"><div style="font-size:20px; margin-top:-5px;margin-right:5px;">{{NowSelBuildingInfo.iCount}}</div> 명</div>
          </div> 
          <div class="d-flex justify-space-between mt-2">
            <div>직원 {{NowSelBuildingInfo.iSKMRCount}}명</div>
            <div>작업자 {{NowSelBuildingInfo.iOtherCount}}명</div>
          </div> 
        </v-card>           
        <div class="mt-2 elevation-3" style="overflow:hidden;  border-radius: 8px; ">
          <v-data-table               
            :items-per-page="-1" 
            fixed-header             
            :headers="headers3" 
            hide-default-footer
            :items="NowSelBuildingInfo.zUserInfo"  
            width="400"          
            :height="200">            
            <template v-slot:item.cType="{ item }">                                
              <v-chip label small v-if ="bNowSelAble && item.cType == '비인가진입'" dark color="red lighten-2" >{{item.cType}}</v-chip>
              <v-chip label small v-if ="bNowSelAble && item.cType == '공정'" color="blue lighten-2" dark >{{item.cType}}/{{item.cReqMessage}}</v-chip>
              <v-chip label small v-if ="bNowSelAble && item.cType == '일반'" color="green lighten-3" dark >{{item.cType}}/{{item.cReqMessage}}</v-chip>                                    
              <v-chip label small v-if ="bNowSelAble && item.cType == 'SKMR'" color="grey lighten-3" >작업</v-chip>       
            </template>

            <template v-slot:no-data>
              <p>No data available.</p>
            </template>
          </v-data-table>
        </div>
      </div>         
    </div>         
  </div>
</template>

<script>
import router from "../router";
import BasicInfo from "@/BasicInfo.js";
import Util from "../Util.js";
import axios from "axios";
import EventBus from '@/eventBus.js';
import { mapState } from "vuex";
import AlertShowSel from "@/components/AlertShowSel.vue";
import SetSOS from "@/components/SetSOS.vue";
import SetEventCheck from "@/components/SetEventCheck.vue";
import MapWindowView from "@/components/MapWindowView.vue";



export default {
  components: {
    AlertShowSel,
    SetSOS,
    SetEventCheck,
    MapWindowView,
  },
  data() {
    return {
      is3d : false,
      isMapMode : true,
      loading : false,    
      intervalId : null,      
      idxData : -1,      
      windowSize : {x:4994, y:2542},
      limitWater : 170,
      isSVG : false,
      scale: 1,
      translateX: 0,
      translateY: 0,
      isDragging: false,
      dragStartX: 0,
      dragStartY: 0,
      initialTranslateX: 0,
      initialTranslateY: 0,
      zPOI_Data : [],
      zDevice_Data : [],

      ZMap : [
        // {cMapGroupId : "01", cBuildingId : "01", cMapId: "00", name : "B1", x : 400, y : 420, iQty : 0},
        // {cMapGroupId : "01", cBuildingId : "02", cMapId: "00", name : "B2", x : 400, y : 520, iQty : 50},
        // {cMapGroupId : "01", cBuildingId : "03", cMapId: "00", name : "B3", x : 400, y : 620, iQty : 50},
        // {cMapGroupId : "01", cBuildingId : "04", cMapId: "00", name : "B4", x : 400, y : 720, iQty : 50},
      ],
      zAPList : [],
      cNowSearchKeyWord : "",
      mapWidth : 100,
      mapHeight : 100,
      bNotiSound : true,
      bNotiSoundReq : true,
      isWindowsEvent : false,
      isShowAlert : false,      
      
      TimerId : null,     
      zEventInfo: [],
      fRateSize : 1, 
      isRunCheck : false,
      isMoreInfo : false,
      zMapInfo : [],
      open: [],    
      zItemPath : [
        {text: '전체',disabled: true, isShow: true, cMapGroupId : "00", cBuildingId:"00",cMapId:"00" },
        {text: '',disabled: true, isShow: false, cMapGroupId : "00", cBuildingId:"00", cMapId:"00" },
        {text: '',disabled: true, isShow: false, cMapGroupId : "00", cBuildingId:"00", cMapId:"00" },
        {text: '',disabled: true, isShow: false, cMapGroupId : "00", cBuildingId:"00", cMapId:"00" },
        {text: '',disabled: true, isShow: false, cMapGroupId : "00", cBuildingId:"00", cMapId:"00" },
      ],
      selection : [],    
      oldSel : [],
      isAlert : false,
      iAlertCount : 0,
      iAlertCheckCount : 0,
      sNowMapId : "",
      sNowBuildingId : "",
      sNowMapGroupId : "",
      bNowSelAble : true,
      
      Records: [],
      items: [],        
      isAlertEvent : false,
      selection2 : ["2"],    
      items2: [
        {id:"1", name:"aaa1", iLev : 0, iCount : 20,},
        {id:"2", name:"aaa2", iLev : 0, iCount : 20,},
        {id:"3", name:"aaa3", iLev : 0, iCount : 20,},{id:"4", name:"aaa4", iLev : 0, iCount : 20,},
        {id:"5", name:"aaa5", iLev : 0, iCount : 20,},
      ],        
      zoom: BasicInfo.Map_Zoom_Default,
      isReqMode: BasicInfo.IsReqMode,            
      isPOS_Near : BasicInfo.IsPOS_Near,    
      
      nowPOS : {
        left : 0, top : 0
      },    
      imgUrl : BasicInfo.FILE_API + "Files/NoImage.svg",
      zApPos: [],    
      zLog : [],

      NowMoreUserInfo:{
        cIdx : "",
        cReqMessage : "",
        cTypeNm : "",
        cType : "",
      },

      NowSelMapGroupInfo:{
        cMapGroupId : "",
        cBuildingId : "",           
        cMapNm : "", 
        iCount : 0,
        iSKMRCount : 0,
        iOtherCount : 0,
        zUserInfo : [
          {cCorpNm : "SKMR", cUserNm : "홍길동1"},
          {cCorpNm : "SKMR", cUserNm : "홍길동2"},
          {cCorpNm : "SKMR", cUserNm : "홍길동3"},
          {cCorpNm : "대화이엔지", cUserNm : "홍길동4"},
          {cCorpNm : "대화이엔지", cUserNm : "홍길동5"},
          {cCorpNm : "대화이엔지", cUserNm : "홍길동6"},
        ],     
      },    

      NowSelBuildingInfo:{
        cMapGroupId : "",
        cBuildingId : "",           
        cMapNm : "정제동", 
        iCount : 0,
        iSKMRCount : 0,
        iOtherCount : 0,
        zUserInfo : [
          {cCorpNm : "SKMR", cUserNm : "홍길동1"},
          {cCorpNm : "SKMR", cUserNm : "홍길동2"},
          {cCorpNm : "SKMR", cUserNm : "홍길동3"},
          {cCorpNm : "대화이엔지", cUserNm : "홍길동4"},
          {cCorpNm : "대화이엔지", cUserNm : "홍길동5"},
          {cCorpNm : "대화이엔지", cUserNm : "홍길동6"},
        ],     
      },

      NowSelMapInfo:{
        cMapGroupId : "",
        cBuildingId : "",
        cMapId : "",     
        cMapNm : "정제동", 
        iCount : 0,
        iSKMRCount : 0,
        iOtherCount : 0,
        zUserInfo : [
          {cCorpNm : "SKMR", cUserNm : "홍길동1"},
          {cCorpNm : "SKMR", cUserNm : "홍길동2"},
          {cCorpNm : "SKMR", cUserNm : "홍길동3"},
          {cCorpNm : "대화이엔지", cUserNm : "홍길동4"},
          {cCorpNm : "대화이엔지", cUserNm : "홍길동5"},
          {cCorpNm : "대화이엔지", cUserNm : "홍길동6"},
        ],     
      },
      headers: [      
        { class: "font-weight-bold subtitle-2", text: "업체/소속명", value: "cCorpNm", align: "center"},      
        { class: "font-weight-bold subtitle-2", text: "이름", value: "cUserNm", align: "center"},            
      ],

      headers2: [      
        { class: "font-weight-bold subtitle-2", text: "업체/소속명", value: "cCorpNm", align: "center", width: "130"},    
        { class: "font-weight-bold subtitle-2", text: "이름", value: "cUserNm", align: "center", width: "80"},      
        { class: "font-weight-bold subtitle-2", text: "작업", value: "cType", align: "left", width: "500"},                
      ],
      headers3: [      
        { class: "font-weight-bold subtitle-2", text: "위치", value: "cMapNm", align: "center", width: "78"},    
        { class: "font-weight-bold subtitle-2", text: "업체/소속명", value: "cCorpNm", align: "center", width: "130"},    
        { class: "font-weight-bold subtitle-2", text: "이름", value: "cUserNm", align: "center", width: "80"},      
        { class: "font-weight-bold subtitle-2", text: "작업", value: "cType", align: "left", width: "500"},                
      ],
      headers4: [      
        { class: "font-weight-bold subtitle-2", text: "위치", value: "cMapNm", align: "center", width: "200"},    
        { class: "font-weight-bold subtitle-2", text: "업체/소속명", value: "cCorpNm", align: "center", width: "130"},    
        { class: "font-weight-bold subtitle-2", text: "이름", value: "cUserNm", align: "center", width: "80"},      
        // { class: "font-weight-bold subtitle-2", text: "작업", value: "cType", align: "left", width: "500"},                
      ],    


      isSideBar : true,    
      
    };
  },
  computed: {
    ...mapState(["UserInfo", "ProgramPermissionInfo"]),   
    imageStyle() {
      return {
        transform: `translate(${this.translateX}px, ${this.translateY}px) scale(${this.scale})`,
        transformOrigin: "0 0",
        transition: this.isDragging ? "none" : "transform 0.1s",
        position: "absolute",
        top: "0",
        left: "0",
      };
    },
  },


  mounted () {
    this.isRunCheck = true;
    
    this.bNotiSound = localStorage.getItem("cNotiSound") != 'N';
    this.bNotiSoundReq = localStorage.getItem("cNotiSoundReq") != 'N';
    

    this.onResize()
    this.initialize();
    this.getEvent();

    setTimeout(() => {
      this.centerContent();      
    }, 100);

    this.SetTimeDashvboard();

    this.TimerId = setInterval(this.ShowAlert, 8000);

    EventBus.$on("onMapPosDashboard", (cApId) => { 
      setTimeout(() => {
        // console.log("onMapPosDashboard",cApId);
          for (let i = 0; i < this.zAPList.length; i++) {
            if (this.zAPList[i].cApId == cApId) {              
              // this.$refs.MapWindowViewRef.SelectFloorPOS("10" + this.zAPList[i].cBuildingId, this.zAPList[i].cMapGroupId + this.zAPList[i].cBuildingId + this.zAPList[i].cMapId);
              break;
            }
          }        
      }, 500);
    });
    EventBus.$on("onDashboard", () => {                
      this.onResize()
      this.initialize();
      this.getEvent();
    });
    EventBus.$on("onLLM_Find", (cType, cKeyword) => {                
      console.log("onLLM_Find", cType, cKeyword);
      this.cNowSearchKeyWord = cKeyword;

      setTimeout(() => {
        this.onSearch();        
      }, 1000);
    });
  },

  beforeDestroy(){
    EventBus.$off('onMapPosDashboard');
    EventBus.$off('onDashboard');
    EventBus.$off('onLLM_Find');
    this.isRunCheck = false;    
    clearInterval(this.TimerId);
  },
  methods: {
    getFontColor (d) {      
      if (d == 'Y') {
        return 'grey--text';
      } else if(d =='N') {
        return 'red--text';
      } else if (d === "OK") {
        return 'light-blue--text'
      } else if (d === "Error") {
        return 'red--text'
      } else if (d === "Warn") {
        return 'yellow--text text--accent-4'
      } else if (d === "Disconnect") {
        return 'red--text'
      } else {
        return 'gray--text'
      }
    },  

    goNotiSound(){
      this.bNotiSound = !this.bNotiSound;
      localStorage.setItem("cNotiSound", Util.sBoolToStr(this.bNotiSound));
    },
    goNotiSoundReq(){
      this.bNotiSoundReq = !this.bNotiSoundReq;
      localStorage.setItem("cNotiSoundReq", Util.sBoolToStr(this.bNotiSoundReq));
    },

    ShowAlert(){
      this.isShowAlert = !this.isShowAlert;      

      if (this.iAlertCount != 0 && this.isShowAlert ) {
        if (this.bNotiSound) {
          var audio = new Audio('EV03.mp3');
          audio.play();
        }
      }
    },

    SetTimeDashvboard(){            
      setTimeout(() => {
        if (!this.isRunCheck) return;
        this.GetDashboardData();             
        this.SetTimeDashvboard();      
      }, 5000);
    },

    getCSS(checked){
      if (checked == 'Y') {
        return 'logChecked';
      } else {
        return 'logNotChecked';
      }
    },
    getDateCSS(checked){
      if (checked == 'Y') {
        return 'logDateChecked';
      } else {
        return 'logNotDateChecked';
      }
    },

    checkAlertEvent(){
      this.isAlertEvent = false;
      for (let i = 0; i < this.zLog.length; i++) {        
        if (this.zLog[i].cChecked == "N") {
          this.isAlertEvent = true;
          break;
        }
      }
    },

    SetEventCheckFunc(data){      
      this.$refs.SetEventCheckRef.Show(data.cIdx);
    },

    onSetEventCheck(cIdx){      
      if (cIdx == "-1") {
        this.zLog = [];
      } else {
        for (let i = 0; i < this.zLog.length; i++) {
          if (this.zLog[i].cIdx == cIdx) {
            // this.zLog[i].cChecked = "Y";
            this.zLog.splice(i, 1); 

            this.checkAlertEvent();
            break;
          }
        }
      }
    },

    onSetSOS(cUserId) {
      if (this.is3d) {
        if (this.$refs.MapWindowViewRef != null) {
          this.$refs.MapWindowViewRef.SetSOS(cUserId, false);
        }
      }
      this.checkData();
    },

    onSelect(data) {            
      this.$refs.SetSOSRef.Show(data.cIdx, data.cTagId, data.cUserId);
    },

    checkData(bShow){    
      if (!this.bCheckAlarm("EV0101")) return;
      var posData = {    
        cChecked : "N",
        cStartDatetime: Util.sFormatDate(Date()) + " 00:00:00",  
        cEndDatetime  : Util.sFormatDate(Date()) + " 23:59:59",
        zMapId : this.UserInfo.zMapIdAll,
        // cGroupId : "SKST",
        cGroupId : this.UserInfo.GroupId,
      };
      axios
        .post(BasicInfo.UIL_API + "GetSOSEventLog", posData, {
          headers: {            
            Authorization: this.$store.state.UserInfo.Token,            
          }
        })
        .then(res => {          
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }

          if (res.data.ResultCd === "00") {            
            this.iAlertCount = res.data.Result1.length;
            
            for (let i = 0; i < this.zApPos.length; i++){
              this.zApPos[i].bAlert = false;
            }

            for (let i = 0; i < this.zApPos.length; i++){              
              if (this.zApPos[i].cBuildingType == 'B') {                
                for (let j = 0; j < res.data.Result1.length; j++){                    
                  if (this.zApPos[i].cMapGroupId == res.data.Result1[j].cMapGroupId && this.zApPos[i].cBuildingId == res.data.Result1[j].cBuildingId){
                    this.zApPos[i].bAlert = true;
                  }
                }
              }
            }

            if (res.data.Result1.length == 0) {
              EventBus.$emit("onShowMsg", false, "비상호출이 없습니다.");            
            } else {            
              if (bShow) {
                this.$refs.AlertShowSelRef.Show(res.data.Result1);
              }              
            }
            if (res.data.Result2.length > 0) {
              this.iAlertCheckCount = res.data.Result2[0].iCount;              
            }            
          } else {
            EventBus.$emit("onShowMsg", true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onErrorSnackbar", "(00)" + err);
        });
    },

    showDevice(data){
      router.push({name: "EventLog", params: {cEvent: data}});
      
    },
    initialize() {    
      this.Search();       
    },
    
    onUpdate(selection) {
      if (selection.length == 0) {
        this.selection = this.oldSel;
        return;
      } else {
        this.oldSel = selection;
        this.selection = selection;
      }      

      setTimeout(() => {
        this.onResize();        
      }, 10);


      this.cNowApId = "";
      this.sNowMapId = "";
      this.sNowBuildingId = "";
      this.sNowMapGroupId = "";
      this.bNowSelAble = true;
      this.zApPos = [];      

      this.NowMoreUserInfo.UserInfo = [];
      
      if (selection.length > 0) {        
        this.imgUrl = selection[0].Path;    
        this.sNowMapId = selection[0].cMapId;        
        this.sNowBuildingId = selection[0].cBuildingId;        
        this.sNowMapGroupId = selection[0].cMapGroupId;                
        this.bNowSelAble = selection[0].bSelAble;                

        this.is3d = false;

        setTimeout(() => {
          this.is3d = this.sNowBuildingId != "00";          
        }, 10);
        
        setTimeout(() => {          
          if (this.is3d) {            
            this.$refs.MapWindowViewRef.loadObj("HD_JS.gltf");

            setTimeout(() => {
              if (this.$refs.MapWindowViewRef != null) {              
                this.$refs.MapWindowViewRef.IniPOI(this.zPOI_Data, this.sNowMapGroupId, this.sNowBuildingId); 
                this.$refs.MapWindowViewRef.IniDevice(this.zDevice_Data, this.sNowMapGroupId, this.sNowBuildingId);  
              }
            }, 100);            
          }

          this.getApPos();        
          setTimeout(() => {            
            if (this.sNowMapId != "00") {   
              let data = {            
                cPosition : selection[0].cParentNm + "/" + selection[0].name,
                cMapGroupId : selection[0].cMapGroupId,
                cBuildingId : selection[0].cBuildingId,
                cBuildingType : selection[0].cBuildingType,
                cMapId : selection[0].cMapId,
              }                         
              this.GetMapDetailUser(data);
            } else if (this.sNowBuildingId != "00") {              
              let data = {            
                cPosition : selection[0].name,
                cMapGroupId : selection[0].cMapGroupId,
                cBuildingId : selection[0].cBuildingId,    
                cBuildingType : selection[0].cBuildingType,            
              }                         
              this.GetMapDetailBuilding(data);            
            } else if (this.sNowMapGroupId != "00") {              
              let data = {            
                cPosition : selection[0].name,
                cMapGroupId : selection[0].cMapGroupId,    
                cBuildingType : selection[0].cBuildingType,            
              }                         
              this.GetMapDetailMapGroup(data);            
            } 
          }, 10);          
        }, 100);
        
      }
    },

    goto(item) {
      this.SelectPoi({cType:"MAP", cApId:item.cMapGroupId + item.cBuildingId + item.cMapId });          
    },

    bCheckMapRole(cMapGroupId, cBuildingId, cMapId) {
      let bCheck = false;
      for (let i in this.UserInfo.zMapGroupId) {
        if (this.UserInfo.zMapGroupId[i] == "00" || cMapGroupId == "00") {
          bCheck = true;
        } else {
          if (this.UserInfo.zMapGroupId[i] == cMapGroupId) {                
            if (this.UserInfo.zBuildingId[i] == "00" ) {
              bCheck = true;
            } else {
              if (this.UserInfo.zBuildingId[i] == cBuildingId ) {                      
                if (this.UserInfo.zMapId[i] == "00" ) {
                  bCheck = true;
                } else {
                  if (this.UserInfo.zMapId[i] == cMapId ) {
                    bCheck = true;
                  }
                }
              }
            }
          }
        }
      }
      return bCheck;
    },
    
    getApPos(){            
      this.zItemPath = [
        {text: '전체',disabled: true, isShow: true, cMapGroupId : "00", cBuildingId:"00",cMapId:"00" },
        {text: '',disabled: true, isShow: false, cMapGroupId : "00", cBuildingId:"00", cMapId:"00" },
        {text: '',disabled: true, isShow: false, cMapGroupId : "00", cBuildingId:"00", cMapId:"00" },
        {text: '',disabled: true, isShow: false, cMapGroupId : "00", cBuildingId:"00", cMapId:"00" },
        {text: '',disabled: true, isShow: false, cMapGroupId : "00", cBuildingId:"00", cMapId:"00" },
      ];
      
      if (this.sNowMapGroupId == "00" && this.sNowBuildingId == "00" && this.sNowMapId == "00") {
        //
      } else {
        if (this.sNowBuildingId == "00" && this.sNowMapId == "00") {
          let sText = "";
          for ( let i = 0; i < this.zMapInfo.length; i++){
            if (this.zMapInfo[i].cMapGroupId == this.sNowMapGroupId && this.zMapInfo[i].cBuildingId == "00" && this.zMapInfo[i].cMapId == "00") {
              sText = this.zMapInfo[i].cMapNm;
              break;
            }             
          }

          this.zItemPath[1] = {text: sText,  isShow: true, cMapGroupId : this.sNowMapGroupId, cBuildingId:"00", cMapId:"00" };
        } else {
          if (this.sNowMapId == "00") {
            let sText1 = "";
            for ( let i = 0; i < this.zMapInfo.length; i++){
              if (this.zMapInfo[i].cMapGroupId == this.sNowMapGroupId && this.zMapInfo[i].cBuildingId == "00" && this.zMapInfo[i].cMapId == "00") {
                sText1 = this.zMapInfo[i].cMapNm;                
                break;
              }             
            }

            let sText2 = "";
            for ( let i = 0; i < this.zMapInfo.length; i++){
              if (this.zMapInfo[i].cMapGroupId == this.sNowMapGroupId && this.zMapInfo[i].cBuildingId == this.sNowBuildingId && this.zMapInfo[i].cMapId == "00") {
                sText2 = this.zMapInfo[i].cMapNm;                
                break;
              }             
            }


            this.zItemPath[1] = {text: sText1,  isShow: true, cMapGroupId : this.sNowMapGroupId, cBuildingId: "00", cMapId:"00" };
            this.zItemPath[2] = {text: sText2,  isShow: true, cMapGroupId : this.sNowMapGroupId, cBuildingId: this.sNowBuildingId, cMapId:"00" };

            
          } else {
            let sText1 = "";
            for ( let i = 0; i < this.zMapInfo.length; i++){
              if (this.zMapInfo[i].cMapGroupId == this.sNowMapGroupId && this.zMapInfo[i].cBuildingId == "00" && this.zMapInfo[i].cMapId == "00") {
                sText1 = this.zMapInfo[i].cMapNm;                
                break;
              }             
            }

            let sText2 = "";
            for ( let i = 0; i < this.zMapInfo.length; i++){
              if (this.zMapInfo[i].cMapGroupId == this.sNowMapGroupId && this.zMapInfo[i].cBuildingId == this.sNowBuildingId && this.zMapInfo[i].cMapId == "00") {
                sText2 = this.zMapInfo[i].cMapNm;
                break;
              }             
            }

            let sText3 = "";            
            for ( let i = 0; i < this.zMapInfo.length; i++){
              if (this.zMapInfo[i].cMapGroupId == this.sNowMapGroupId && this.zMapInfo[i].cBuildingId == this.sNowBuildingId && this.zMapInfo[i].cMapId == this.sNowMapId) {
                sText3 = this.zMapInfo[i].cMapNm;
                break;
              }             
            }

            this.zItemPath[1] = {text: sText1,  isShow: true, cMapGroupId : this.sNowMapGroupId, cBuildingId: "00", cMapId:"00" };
            this.zItemPath[2] = {text: sText2,  isShow: true, cMapGroupId : this.sNowMapGroupId, cBuildingId: this.sNowBuildingId, cMapId:"00" };
            this.zItemPath[3] = {text: sText3,  isShow: true, cMapGroupId : this.sNowMapGroupId, cBuildingId: this.sNowBuildingId, cMapId: this.sNowMapId };
          }
        }
      }

      this.zApPos = [];
      var posData = {    
        cMapId : this.sNowMapId,
        cMapGroupId : this.sNowMapGroupId,
        cBuildingId : this.sNowBuildingId,
      };

      
      axios
        .post(BasicInfo.UIL_API + "GetApPos", posData, {
          headers: {            
            Authorization: this.$store.state.UserInfo.Token,
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            for (let i = 0; i < res.data.Result.length; i++) {
              var item = {
                cApId: res.data.Result[i].cApId,
                x : res.data.Result[i].x,
                y : res.data.Result[i].y,
                cPosition : res.data.Result[i].cPosition,
                cMac : res.data.Result[i].cMac,
                cType: res.data.Result[i].cType,
                cMapGroupId : res.data.Result[i].cMapGroupId,
                cBuildingId : res.data.Result[i].cBuildingId,
                cMapId : res.data.Result[i].cMapId,
                cSel : res.data.Result[i].cSel,
                cBuildingType : res.data.Result[i].cBuildingType,
                iCount : 0,
                bSel : false,                
                bAllow : true,
                bAlert : false,
                cNm : "TEST",
              }             

              if (item.cType == "AP"){                
                if (res.data.Result[i].cInOut != "NONE"){
                  item.cSel = "N";
                }
              }
              
              // if (this.bCheckMapRole(item.cMapGroupId, item.cBuildingId, item.cMapId)) {
                this.zApPos.push(item);
              // }              
            }    


            this.GetDashboardData();                  
          } else {
            EventBus.$emit("onShowMsg", true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onErrorSnackbar", "(01)"+err);
        });
    },


    SelectPoi(data) {   
      if (data.cType == 'AP') return;           

      this.onResize();     
      

      this.selection = [];      
      for (let i = 0; i < this.items.length; i++) {        
        if (this.items[i].id == data.cApId) {              
          this.selection.push(this.items[i]);                               
          this.oldSel = this.selection;
          this.sNowMapId = this.items[i].cMapId;
          this.sNowMapGroupId = this.items[i].cMapGroupId;
          this.sNowBuildingId = this.items[i].cBuildingId;
          this.bNowSelAble = this.items[i].bSelAble;                
          this.imgUrl = this.items[i].Path;
          this.getApPos();    
          return;
        }
      }

      for (let i = 0; i < this.items.length; i++) {
        for (let j = 0; j < this.items[i].children.length; j++) {          
          if (this.items[i].children[j].id == data.cApId) {              
            this.selection.push(this.items[i].children[j]);                               
            this.oldSel = this.selection;
            this.sNowMapId = this.items[i].children[j].cMapId;
            this.sNowMapGroupId = this.items[i].children[j].cMapGroupId;
            this.sNowBuildingId = this.items[i].children[j].cBuildingId;
            this.bNowSelAble = this.items[i].children[j].bSelAble;                
            this.imgUrl = this.items[i].children[j].Path;
            this.getApPos();    
            return;
          }
        }
      }
      for (let i = 0; i < this.items.length; i++) {
        for (let j = 0; j < this.items[i].children.length; j++) {
          for (let z = 0; z < this.items[i].children[j].children.length; z++) {            
            if (this.items[i].children[j].children[z].id == data.cApId) {              
              this.selection.push(this.items[i].children[j].children[z]);                               
              this.oldSel = this.selection;
              this.sNowMapId = this.items[i].children[j].children[z].cMapId;
              this.sNowMapGroupId = this.items[i].children[j].children[z].cMapGroupId;
              this.sNowBuildingId = this.items[i].children[j].children[z].cBuildingId;
              this.bNowSelAble = this.items[i].children[j].children[z].bSelAble;                
              this.imgUrl = this.items[i].children[j].children[z].Path;
              this.getApPos();    
              return;
            }
          }
        }
      }
      for (let i = 0; i < this.items.length; i++) {
        for (let j = 0; j < this.items[i].children.length; j++) {
          for (let z = 0; z < this.items[i].children[j].children.length; z++) {
            for (let x = 0; x < this.items[i].children[j].children[z].children.length; x++) {
              if (this.items[i].children[j].children[z].children[x].id == data.cApId) {              
                this.selection.push(this.items[i].children[j].children[z].children[x]);                               
                this.oldSel = this.selection;
                this.sNowMapId = this.items[i].children[j].children[z].children[x].cMapId;
                this.sNowMapGroupId = this.items[i].children[j].children[z].children[x].cMapGroupId;
                this.sNowBuildingId = this.items[i].children[j].children[z].children[x].cBuildingId;
                this.bNowSelAble = this.items[i].children[j].children[z].children[x].bSelAble;                
                this.imgUrl = this.items[i].children[j].children[z].children[x].Path;
                this.getApPos();    
                return;
              }
            }
          }
        }
      }      
    },
    

    Search(){
      this.open = [];   
      this.zMapInfo = [];
      this.items = [];
      this.selection = [];
      this.sNowMapId = "";
      this.zApPos = [];
      this.imgUrl = BasicInfo.FILE_API + "Files/NoImage.svg";

      var posData = {      
        cNowSiteId : this.UserInfo.NowSiteId,
      };

      axios
        .post(BasicInfo.UIL_API + "GetMapInfoWithPOI", posData, {
          headers: {                        
            Authorization: this.$store.state.UserInfo.Token,            
          },
          check: {
            UserId : this.UserInfo.UserId,
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            let idx = 0;
            for (let i = 0; i < res.data.Result1.length; i++) {                        
              console.log(res.data.Result1[i].cMapGroupId);

              if (res.data.Result1[i].cMapGroupId != "00" && res.data.Result1[i].cBuildingId != "00" && res.data.Result1[i].cMapId == "00") {
                let a = {
                  cMapGroupId : res.data.Result1[i].cMapGroupId,
                  cBuildingId : res.data.Result1[i].cBuildingId,
                  cMapId : res.data.Result1[i].cMapId,
                  name :  res.data.Result1[i].cMapNm,
                  iQty : 0,                  
                  x : 400, 
                  y : 420 + idx * 100,
                }

                idx = idx + 1;
                this.ZMap.push(a);
              }  
              
            }
            
            this.zMapInfo = res.data.Result1;
            for (let i = 0; i < res.data.Result1.length; i++) {
              if (res.data.Result1[i].iLev == 0) {
                let item = {
                  id: res.data.Result1[i].cMapGroupId + res.data.Result1[i].cBuildingId + res.data.Result1[i].cMapId,
                  cMapGroupId: res.data.Result1[i].cMapGroupId,
                  cBuildingId: res.data.Result1[i].cBuildingId,                  
                  cMapId: res.data.Result1[i].cMapId,
                  bSelAble : res.data.Result1[i].cSel == "Y",
                  name: res.data.Result1[i].cMapNm,
                  file: res.data.Result1[i].cFileId,
                  iLev: res.data.Result1[i].iLev,
                  Path: BasicInfo.FILE_API + res.data.Result1[i].cPath,
                  Order: res.data.Result1[i].cOrder,           
                  cBuildingType: res.data.Result1[i].cType,     
                  cParentNm : "",
                  children: [],
                  iCount : 0,
                  iCountOut : 0,
                }
                this.items.push(item);                           
                this.open.push(item);
              }              
            }

            for (let i = 0; i < res.data.Result1.length; i++) {
              if (res.data.Result1[i].iLev == 1) {                
                let item = {
                  id: res.data.Result1[i].cMapGroupId + res.data.Result1[i].cBuildingId + res.data.Result1[i].cMapId,
                  cMapGroupId: res.data.Result1[i].cMapGroupId,
                  cBuildingId: res.data.Result1[i].cBuildingId,
                  cMapId: res.data.Result1[i].cMapId,
                  bSelAble : res.data.Result1[i].cSel == "Y",
                  name: res.data.Result1[i].cMapNm,
                  file: res.data.Result1[i].cFileId,
                  iLev: res.data.Result1[i].iLev,
                  Path: BasicInfo.FILE_API + res.data.Result1[i].cPath,
                  Order: res.data.Result1[i].cOrder,            
                  cBuildingType: res.data.Result1[i].cType,    
                  cParentNm : this.items[0].name,
                  children: [],
                  iCount : 0,
                  iCountOut : 0,
                }
                this.items[0].children.push(item);   
                this.open.push(item);                      
              }              
            }

            for (let i = 0; i < res.data.Result1.length; i++) {
              if (res.data.Result1[i].iLev == 2) {
                for (let j = 0; j < this.items[0].children.length; j++) {                  
                  if (this.items[0].children[j].cMapGroupId == res.data.Result1[i].cMapGroupId) {     
                    let item = {
                      id: res.data.Result1[i].cMapGroupId + res.data.Result1[i].cBuildingId + res.data.Result1[i].cMapId,
                      cMapGroupId: res.data.Result1[i].cMapGroupId,
                      cBuildingId: res.data.Result1[i].cBuildingId,                      
                      cMapId: res.data.Result1[i].cMapId,
                      bSelAble : res.data.Result1[i].cSel == "Y",
                      name: res.data.Result1[i].cMapNm,
                      file: res.data.Result1[i].cFileId,
                      iLev: res.data.Result1[i].iLev,
                      Path: BasicInfo.FILE_API + res.data.Result1[i].cPath,
                      Order: res.data.Result1[i].cOrder,          
                      cBuildingType: res.data.Result1[i].cType,      
                      cParentNm : this.items[0].children[j].name,
                      children: [],
                      iCount : 0,
                    }
                    this.items[0].children[j].children.push(item);
                    break;
                  }
                }
              }              
            }

            for (let i = 0; i < res.data.Result1.length; i++) {
              if (res.data.Result1[i].iLev == 3) {
                for (let j = 0; j < this.items[0].children.length; j++) {    
                  if (this.items[0].children[j].cMapGroupId == res.data.Result1[i].cMapGroupId) {     
                    for (let z = 0; z < this.items[0].children[j].children.length; z++) {    
                      if (this.items[0].children[j].children[z].cBuildingId == res.data.Result1[i].cBuildingId) {     
                        let item = {
                          id: res.data.Result1[i].cMapGroupId + res.data.Result1[i].cBuildingId + res.data.Result1[i].cMapId,
                          cMapGroupId: res.data.Result1[i].cMapGroupId,
                          cBuildingId: res.data.Result1[i].cBuildingId,
                          cMapId: res.data.Result1[i].cMapId,
                          bSelAble : res.data.Result1[i].cSel == "Y",
                          name: res.data.Result1[i].cMapNm,
                          file: res.data.Result1[i].cFileId,
                          iLev: res.data.Result1[i].iLev,
                          Path: BasicInfo.FILE_API + res.data.Result1[i].cPath,
                          Order: res.data.Result1[i].cOrder,           
                          cBuildingType: res.data.Result1[i].cType,
                          cParentNm : this.items[0].children[j].children[z].name,     
                          children: [],
                          iCount : 0,
                        }
                        this.items[0].children[j].children[z].children.push(item);
                        break;
                      }
                    }
                  }
                }
              }              
            }
            
            if (this.items.length > 0) {      
              this.selection.push(this.items[0]);                
              this.oldSel = this.selection;
            }     

            this.zPOI_Data = res.data.Result2;
            this.zDevice_Data = res.data.Result3;

            // setTimeout(() => {
            //   // console.log(res.data.Result3);
            //   if (this.$refs.MapWindowViewRef != null) {

                
            //     // this.$refs.MapWindowViewRef.IniPOI(res.data.Result2);  
            //     this.$refs.MapWindowViewRef.IniDevice(this.zDevice_Data);  
            //     // this.$refs.MapWindowViewRef.IniAsset(res.data.Result4);  
            //   }
            // }, 100);
          } else {
            EventBus.$emit("onShowMsg", true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onErrorSnackbar", "(02)"+err);
        });
    },
    

    GetDashboardData() {
      var posData = {
        zMapId : this.UserInfo.zMapIdAll,        
        cNowSiteId : this.UserInfo.NowSiteId,
      };
      axios
        .post(BasicInfo.UIL_API + "GetDashboardData", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token,            
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }

          if (!this.isRunCheck) {
            return;
          }

          this.zAPList = res.data.Result7;
          try {
            if (this.$refs.MapWindowViewRef != null) {
              this.$refs.MapWindowViewRef.SetDevice(res.data.Result7);          
              this.$refs.MapWindowViewRef.SetSensor(res.data.Result5);   
              this.$refs.MapWindowViewRef.SetPOI(this.zPOI_Data);
              setTimeout(() => {                
                this.$refs.MapWindowViewRef.SetUser(res.data.Result8);                          
              }, 100);
            }
          } catch (error) {
            console.log("Dashboard.vue(GetDashboardData)" + error)
          }

          for (let i = 0; i < this.ZMap.length; i++) {
            this.ZMap[i].iQty = 0;
          }

          for (let i = 0; i < this.zPOI_Data.length; i++) {
            this.zPOI_Data[i].iCount = 0;
          }

          
          for (let i = 0; i < this.ZMap.length; i++) {
            for (let j = 0; j < res.data.Result2.length; j++) {
              if ( this.ZMap[i].cMapGroupId == res.data.Result2[j].cMapGroupId && this.ZMap[i].cBuildingId == res.data.Result2[j].cBuildingId) {
                this.ZMap[i].iQty = this.ZMap[i].iQty + res.data.Result2[j].iCount;              
              }
            }            
          }
          for (let i = 0; i < this.zPOI_Data.length; i++) {
            for (let j = 0; j < res.data.Result2.length; j++) {
              if ( this.zPOI_Data[i].cMapGroupId == res.data.Result2[j].cMapGroupId && this.zPOI_Data[i].cBuildingId == res.data.Result2[j].cBuildingId
              && this.zPOI_Data[i].cMapId == res.data.Result2[j].cMapId) {
                this.zPOI_Data[i].iCount = this.zPOI_Data[i].iCount + res.data.Result2[j].iCount;              
              }
            }            
          }

          this.iAlertCount = 0;
          this.iAlertCheckCount = 0;   

          
          if (res.data.ResultCd === "00") {                    
            if (this.bCheckAlarm("EV0102")) {              
              this.zLog = res.data.Result1;
            }                        

            this.checkAlertEvent();
            for (let i = 0; i < this.items.length; i++) {
              this.items[i].iCount = 0;                             
            }            

            for (let j = 0; j < res.data.Result2.length; j++) {            
              for (let i = 0; i < this.items.length; i++) {
                for (let z = 0; z < this.items[i].children.length; z++) {                  
                  this.items[i].children[z].iCount = 0;              
                  this.items[i].children[z].iCountOut = 0;                
                }
              }              
            }

            for (let j = 0; j < res.data.Result2.length; j++) {            
              for (let i = 0; i < this.items.length; i++) {
                for (let z = 0; z < this.items[i].children.length; z++) {
                  for (let x = 0; x < this.items[i].children[z].children.length; x++) {                    
                    this.items[i].children[z].children[x].iCount = 0;                         
                  }
                }
              }              
            }

            for (let j = 0; j < res.data.Result2.length; j++) {            
              for (let i = 0; i < this.items.length; i++) {
                for (let z = 0; z < this.items[i].children.length; z++) {
                  for (let x = 0; x < this.items[i].children[z].children.length; x++) {                    
                    for (let y = 0; y < this.items[i].children[z].children[x].children.length; y++) {                                          
                      this.items[i].children[z].children[x].children[y].iCount = 0;                      
                    }
                  }
                }
              }              
            }


            //Count Check
            var iTotal = 0;            
            for (let j = 0; j < res.data.Result2.length; j++) {
              if (this.bCheckMapRole(res.data.Result2[j].cMapGroupId, res.data.Result2[j].cBuildingId, res.data.Result2[j].cMapId)) {
                if (res.data.Result2[j].cIdx != "NNNNNN") {
                  iTotal = iTotal + res.data.Result2[j].iCount;
                  for (let i = 0; i < this.items.length; i++) {
                    if (this.items[i].id == res.data.Result2[j].cIdx) {
                      this.items[i].iCount = res.data.Result2[j].iCount;
                      break;
                    }                
                  }
                }              
              }
            }     

            if (this.items.length > 0) {
              if (this.items[0].iCount != undefined) this.items[0].iCount = iTotal;
            }
            
            for (let j = 0; j < res.data.Result2.length; j++) {            
              if (this.bCheckMapRole(res.data.Result2[j].cMapGroupId, res.data.Result2[j].cBuildingId, res.data.Result2[j].cMapId)) {
                for (let i = 0; i < this.items.length; i++) {
                  for (let z = 0; z < this.items[i].children.length; z++) {
                    if (this.items[i].children[z].cMapGroupId == res.data.Result2[j].cMapGroupId) {        
                      this.items[i].children[z].iCount = this.items[i].children[z].iCount + res.data.Result2[j].iCount;           
                       if (res.data.Result2[j].cIsNowAp == 'N') {
                         this.items[i].children[z].iCountOut = this.items[i].children[z].iCountOut + res.data.Result2[j].iCount;                                 
                       }
                      break;
                    }
                  }
                }              
              }
            }

            for (let j = 0; j < res.data.Result2.length; j++) {            
              if (res.data.Result2[j].cIsNowAp == 'Y') {
                if (this.bCheckMapRole(res.data.Result2[j].cMapGroupId, res.data.Result2[j].cBuildingId, res.data.Result2[j].cMapId)) {
                  for (let i = 0; i < this.items.length; i++) {
                    for (let z = 0; z < this.items[i].children.length; z++) {
                      for (let x = 0; x < this.items[i].children[z].children.length; x++) {                    
                        if (this.items[i].children[z].children[x].cMapGroupId == res.data.Result2[j].cMapGroupId && this.items[i].children[z].children[x].cBuildingId == res.data.Result2[j].cBuildingId) {                       
                          this.items[i].children[z].children[x].iCount = this.items[i].children[z].children[x].iCount + res.data.Result2[j].iCount;                               
                          break;
                        }
                      }
                    }
                  }
                }
              }
            }

            for (let j = 0; j < res.data.Result2.length; j++) {            
              if (res.data.Result2[j].cIsNowAp == 'Y') {
                if (this.bCheckMapRole(res.data.Result2[j].cMapGroupId, res.data.Result2[j].cBuildingId, res.data.Result2[j].cMapId)) {
                  for (let i = 0; i < this.items.length; i++) {
                    for (let z = 0; z < this.items[i].children.length; z++) {
                      for (let x = 0; x < this.items[i].children[z].children.length; x++) {                    
                        for (let y = 0; y < this.items[i].children[z].children[x].children.length; y++) {                                          
                          if (this.items[i].children[z].children[x].children[y].id == res.data.Result2[j].cIdx) {                                  
                            this.items[i].children[z].children[x].children[y].iCount = this.items[i].children[z].children[x].children[y].iCount + res.data.Result2[j].iCount;                                    
                            break;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }



            for (let j = 0; j < this.zApPos.length; j++) {                            
              if (this.zApPos[j].cType == "MAP") {
                for (let i = 0; i < this.items.length; i++) {
                  if (this.items[i].id == this.zApPos[j].cApId) {
                    this.zApPos[j].iCount = this.items[i].iCount;
                    break;
                  } else {
                    for (let z = 0; z < this.items[i].children.length; z++) {
                      if (this.items[i].children[z].id == this.zApPos[j].cApId) {        
                        this.zApPos[j].iCount = this.items[i].children[z].iCount;                         
                        break;
                      } else {
                        for (let x = 0; x < this.items[i].children[z].children.length; x++) {
                          if (this.items[i].children[z].children[x].id == this.zApPos[j].cApId) {        
                            this.zApPos[j].iCount = this.items[i].children[z].children[x].iCount;                         
                            break;
                          } else {
                            for (let y = 0; y < this.items[i].children[z].children[x].children.length; y++) {
                              if (this.items[i].children[z].children[x].children[y].id == this.zApPos[j].cApId) {        
                                this.zApPos[j].iCount = this.items[i].children[z].children[x].children[y].iCount;                         
                                break;                           
                              }
                            }
                          }
                        }
                      }
                    }
                  }                
                }
              }              
            }

            
            for (let j = 0; j < this.zApPos.length; j++) {   
              if (this.zApPos[j].cType == "AP") {
                for (let i = 0; i < res.data.Result3.length; i++) {
                  if (res.data.Result3[i].cApId == this.zApPos[j].cApId) {
                    this.zApPos[j].iCount = res.data.Result3[i].iCount;
                    break;
                  }                
                }
              }      
            }

            for (let j = 0; j < this.zApPos.length; j++) {   
              this.zApPos[j].bAllow = true;
              if (this.zApPos[j].cType == "AP") {                                
                for (let i = 0; i < res.data.Result6.length; i++) {
                  if (res.data.Result6[i].cApId == this.zApPos[j].cApId) {
                    this.zApPos[j].bAllow = false;
                    break;
                  }                
                }
              } else {
                // console.log(">>>", res.data.Result2);
                for (let i = 0; i < res.data.Result2.length; i++) {
                  //console.log("--->>>", res.data.Result2[i].cIdx, res.data.Result2[i].cIdx.substring(0,2));
                  // console.log("--->>>", res.data.Result2[i].cIdx, res.data.Result2[i].cIsAllow, res.data.Result2[i].cIsNowAp);
                  if (res.data.Result2[i].cIsAllow == "N" && res.data.Result2[i].cIsNowAp == "Y") {
                    // console.log("--->>>", res.data.Result2[i].cIdx, res.data.Result2[i].cIdx.substring(0,2));
                    if (this.zApPos[j].cBuildingId == "00")  {
                      if (res.data.Result2[i].cIdx.substring(0,2) == this.zApPos[j].cMapGroupId) {
                        this.zApPos[j].bAllow = false;
                        break;
                      }                
                    } else if (this.zApPos[j].cMapId == "00")  {
                      if (res.data.Result2[i].cIdx.substring(0,4) == this.zApPos[j].cMapGroupId + this.zApPos[j].cBuildingId) {
                        this.zApPos[j].bAllow = false;
                        break;
                      }                
                    } else {
                      if (res.data.Result2[i].cIdx == this.zApPos[j].cMapGroupId + this.zApPos[j].cBuildingId + this.zApPos[j].cMapId) {
                        this.zApPos[j].bAllow = false;
                        break;
                      }                
                    }
                  }
                }
              }
            }
            
            if (this.bCheckAlarm("EV0101")) {
              
              for (let i = 0; i < res.data.Result4.length; i++) {
                if (res.data.Result4[i].cChecked == 'N') {
                  this.iAlertCount = res.data.Result4[i].iCount;     

                  if (this.iAlertCount > 0) {
                    this.checkData(false);
                  } else {
                    for (let i = 0; i < this.zApPos.length; i++){
                      this.zApPos[i].bAlert = false;
                    }
                  }                  
                } else if (res.data.Result4[i].cChecked == 'Y') {
                  this.iAlertCheckCount = res.data.Result4[i].iCount;                
                }                
              }    
            }
            
          } else {
            EventBus.$emit("onShowMsg", true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        // .catch(err => {
        //   EventBus.$emit("onErrorSnackbar", "(03)"+err);
        // });
    },   


    goSOS(){
      router.push({name: "EventLog"});      
    },

    GetMapDetailUser(data){
      this.NowSelMapInfo.cMapNm = data.cPosition;

      this.NowSelMapInfo.cMapGroupId = data.cMapGroupId;
      this.NowSelMapInfo.cBuildingId = data.cBuildingId;
      this.NowSelMapInfo.cMapId = data.cMapId;     

      this.NowSelMapInfo.iCount = 0;
      this.NowSelMapInfo.iSKMRCount = 0;
      this.NowSelMapInfo.iOtherCount = 0;
      this.NowSelMapInfo.zUserInfo = [];


      
      var posData = {    
        cMapGroupId : this.NowSelMapInfo.cMapGroupId,
        cBuildingId : this.NowSelMapInfo.cBuildingId,
        cMapId : this.NowSelMapInfo.cMapId,
      };
      
      axios
        .post(BasicInfo.UIL_API + "GetMapDetailUser", posData, {
          headers: {                        
            Authorization: this.$store.state.UserInfo.Token,
          }
        })
        .then(res => {          
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {       

            this.NowSelMapInfo.zUserInfo = res.data.Result;
            this.NowSelMapInfo.iCount = res.data.Result.length;            

            for (let i =0; i < res.data.Result.length; i++) {
              if (this.NowSelMapInfo.zUserInfo[i].cPartNm != ""){
                if (this.NowSelMapInfo.zUserInfo[i].cCorpNm == "SKST"){
                  this.NowSelMapInfo.zUserInfo[i].cCorpNm = this.NowSelMapInfo.zUserInfo[i].cPartNm;
                } else {
                  this.NowSelMapInfo.zUserInfo[i].cCorpNm = this.NowSelMapInfo.zUserInfo[i].cCorpNm + "/" + this.NowSelMapInfo.zUserInfo[i].cPartNm;
                }                
              }

              if (this.NowSelMapInfo.zUserInfo[i].cIdx == "NONE") {
                this.NowSelMapInfo.zUserInfo[i].cType = "비인가진입";
                if (data.cBuildingType == "B"){
                  this.NowSelMapInfo.zUserInfo[i].cType = "";
                }
              } else {
                if (this.NowSelMapInfo.zUserInfo[i].cIdx == "SKMR") {
                  this.NowSelMapInfo.zUserInfo[i].cType = "SKMR";
                } else {
                  if (this.NowSelMapInfo.zUserInfo[i].cType == "G" ) {
                    this.NowSelMapInfo.zUserInfo[i].cType = "일반";
                  } else if (this.NowSelMapInfo.zUserInfo[i].cType == "J" ) {
                    this.NowSelMapInfo.zUserInfo[i].cType = "공정";
                  }
                }
              }
              if (res.data.Result[i].cCorpId == BasicInfo.CORP_DEFAULT) {
                this.NowSelMapInfo.iSKMRCount = this.NowSelMapInfo.iSKMRCount + 1;
              }              
              for (let j = 0; j < this.zApPos.length; j++) {                   
                if (this.zApPos[j].cType == "AP" && res.data.Result[i].cApId == this.zApPos[j].cApId) {
                  this.NowSelMapInfo.zUserInfo[i].isSKMR = !this.isWorkerCheck(res.data.Result[i].cCorpId);

                  this.NowSelMapInfo.zUserInfo[i].x = parseInt(this.zApPos[j].x) + this.getRandom();                  
                  this.NowSelMapInfo.zUserInfo[i].y = parseInt(this.zApPos[j].y) + this.getRandom();      
                  break;                                  
                }
              }   
            }
            this.NowSelMapInfo.iOtherCount = this.NowSelMapInfo.iCount - this.NowSelMapInfo.iSKMRCount;
          } else {
            EventBus.$emit("onShowMsg", true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onErrorSnackbar", "(04)"+err);
        });
      
    },

    isWorkerCheck(cCorpId) {
      for (let i = 0; i < BasicInfo.CORPS.length; i++) {
        if (cCorpId == BasicInfo.CORPS[i]){
          return false;          
        }          
      }

      return true;
    },

    GetMapDetailBuilding(data){      
      // console.log(data);     
      this.NowSelBuildingInfo.cMapNm = data.cPosition;

      this.NowSelBuildingInfo.cMapGroupId = data.cMapGroupId;
      this.NowSelBuildingInfo.cBuildingId = data.cBuildingId;
      this.NowSelBuildingInfo.cMapId = data.cMapId;     

      this.NowSelBuildingInfo.iCount = 0;
      this.NowSelBuildingInfo.iSKMRCount = 0;
      this.NowSelBuildingInfo.iOtherCount = 0;
      this.NowSelBuildingInfo.zUserInfo = [];


      var posData = {    
        cMapGroupId : this.NowSelBuildingInfo.cMapGroupId,
        cBuildingId : this.NowSelBuildingInfo.cBuildingId,
        cMapId : this.NowSelBuildingInfo.cMapId,
      };
      axios
        .post(BasicInfo.UIL_API + "GetMapDetailBuilding", posData, {
          headers: {                        
            Authorization: this.$store.state.UserInfo.Token,
          }
        })
        .then(res => {          
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {            
            // console.log("GetMapDetailBuilding", data.cBuildingType, res.data.Result);

            this.NowSelBuildingInfo.zUserInfo = res.data.Result;
            this.NowSelBuildingInfo.iCount = res.data.Result.length;            

            for (let i =0; i < res.data.Result.length; i++) {              
              if (this.NowSelBuildingInfo.zUserInfo[i].cPartNm != ""){
                if (this.NowSelBuildingInfo.zUserInfo[i].cCorpNm == "SKST"){
                  this.NowSelBuildingInfo.zUserInfo[i].cCorpNm = this.NowSelBuildingInfo.zUserInfo[i].cPartNm;
                } else {                  
                  this.NowSelBuildingInfo.zUserInfo[i].cCorpNm = this.NowSelBuildingInfo.zUserInfo[i].cCorpNm + "/" + this.NowSelBuildingInfo.zUserInfo[i].cPartNm;
                }
              }

              if (this.NowSelBuildingInfo.zUserInfo[i].cIdx == "NONE") {
                this.NowSelBuildingInfo.zUserInfo[i].cType = "비인가진입";

                if (data.cBuildingType == 'B') {
                  this.NowSelBuildingInfo.zUserInfo[i].cType = "";
                }
              } else {
                if (this.NowSelBuildingInfo.zUserInfo[i].cIdx == "SKMR") {
                  this.NowSelBuildingInfo.zUserInfo[i].cType = "SKMR";
                } else {
                  if (this.NowSelBuildingInfo.zUserInfo[i].cType == "G" ) {
                    this.NowSelBuildingInfo.zUserInfo[i].cType = "일반";
                  } else if (this.NowSelBuildingInfo.zUserInfo[i].cType == "J" ) {
                    this.NowSelBuildingInfo.zUserInfo[i].cType = "공정";
                  }
                }
              }
              if (res.data.Result[i].cCorpId == BasicInfo.CORP_DEFAULT) {
                this.NowSelBuildingInfo.iSKMRCount = this.NowSelBuildingInfo.iSKMRCount + 1;
              }              

              for (let j = 0; j < this.zApPos.length; j++) {                   
                if (this.zApPos[j].cType == "AP" && res.data.Result[i].cApId == this.zApPos[j].cApId) {
                  this.NowSelBuildingInfo.zUserInfo[i].isSKMR = !this.isWorkerCheck(res.data.Result[i].cCorpId);

                  this.NowSelBuildingInfo.zUserInfo[i].x = parseInt(this.zApPos[j].x) + this.getRandom();                  
                  this.NowSelBuildingInfo.zUserInfo[i].y = parseInt(this.zApPos[j].y) + this.getRandom();      
                  break;                                  
                }
              }   
            }

            this.NowSelBuildingInfo.iOtherCount = this.NowSelBuildingInfo.iCount - this.NowSelBuildingInfo.iSKMRCount;

            

          } else {
            EventBus.$emit("onShowMsg", true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onErrorSnackbar", "(05)"+err);
        });
    },

    GetMapDetailMapGroup(data){            
      this.NowSelMapGroupInfo.cMapNm = data.cPosition;

      this.NowSelMapGroupInfo.cMapGroupId = data.cMapGroupId;
      
      this.NowSelMapGroupInfo.iCount = 0;
      this.NowSelMapGroupInfo.iSKMRCount = 0;
      this.NowSelMapGroupInfo.iOtherCount = 0;
      this.NowSelMapGroupInfo.zUserInfo = [];


      var posData = {    
        cMapGroupId : this.NowSelMapGroupInfo.cMapGroupId,
        cBuildingId : this.NowSelMapGroupInfo.cBuildingId,
        cMapId : this.NowSelMapGroupInfo.cMapId,
      };
      axios
        .post(BasicInfo.UIL_API + "GetMapDetailMapGroup", posData, {
          headers: {                        
            Authorization: this.$store.state.UserInfo.Token,
          }
        })
        .then(res => {          
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {            
            this.NowSelMapGroupInfo.zUserInfo = res.data.Result;
            this.NowSelMapGroupInfo.iCount = res.data.Result.length;            

            for (let i =0; i < res.data.Result.length; i++) {
              if (this.NowSelMapGroupInfo.zUserInfo[i].cCorpNm == "SKST"){
                this.NowSelMapGroupInfo.zUserInfo[i].cCorpNm = this.NowSelMapGroupInfo.zUserInfo[i].cPartNm;
              } else {
                if (this.NowSelMapGroupInfo.zUserInfo[i].cPartNm != ""){
                  this.NowSelMapGroupInfo.zUserInfo[i].cCorpNm = this.NowSelMapGroupInfo.zUserInfo[i].cCorpNm + "/" + this.NowSelMapGroupInfo.zUserInfo[i].cPartNm;
                }
              }

              this.NowSelMapGroupInfo.zUserInfo[i].cMapNm = this.NowSelMapGroupInfo.zUserInfo[i].cMapFullNm + " 근처";
              if (this.NowSelMapGroupInfo.zUserInfo[i].cIdx == "NONE") {
                this.NowSelMapGroupInfo.zUserInfo[i].cType = "비인가진입";
              } else {
                if (this.NowSelMapGroupInfo.zUserInfo[i].cIdx == "SKMR") {
                  this.NowSelMapGroupInfo.zUserInfo[i].cType = "SKMR";
                } else {
                  if (this.NowSelMapGroupInfo.zUserInfo[i].cType == "G" ) {
                    this.NowSelMapGroupInfo.zUserInfo[i].cType = "일반";
                  } else if (this.NowSelMapGroupInfo.zUserInfo[i].cType == "J" ) {
                    this.NowSelMapGroupInfo.zUserInfo[i].cType = "공정";
                  }
                }
              }
              if (res.data.Result[i].cCorpId == BasicInfo.CORP_DEFAULT) {
                this.NowSelMapGroupInfo.iSKMRCount = this.NowSelMapGroupInfo.iSKMRCount + 1;
              }              

              for (let j = 0; j < this.zApPos.length; j++) {                   
                if (this.zApPos[j].cType == "AP" && res.data.Result[i].cApId == this.zApPos[j].cApId) {
                  this.NowSelMapGroupInfo.zUserInfo[i].isSKMR = !this.isWorkerCheck(res.data.Result[i].cCorpId);

                  this.NowSelMapGroupInfo.zUserInfo[i].x = parseInt(this.zApPos[j].x) + this.getRandom();                  
                  this.NowSelMapGroupInfo.zUserInfo[i].y = parseInt(this.zApPos[j].y) + this.getRandom();      
                  break;                                  
                }
              }   
            }

            this.NowSelMapGroupInfo.iOtherCount = this.NowSelMapGroupInfo.iCount - this.NowSelMapGroupInfo.iSKMRCount;

            

          } else {
            EventBus.$emit("onShowMsg", true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onErrorSnackbar", "(06)"+err);
        });
    },

    getRandom(){
      let itmp = Math.floor(Math.random() * 200) - 100;
      if (itmp > 0) {
        itmp = itmp + 50;
      } else {
        itmp = itmp - 50;
      }
      return itmp;
    },

    // moreUserInfo(item){
    //   this.NowMoreUserInfo.cIdx = "";
    //   this.NowMoreUserInfo.cReqMessage = "";
    //   this.NowMoreUserInfo.cTypeNm = "";
    //   this.NowMoreUserInfo.cType = "";
      
    //   var posData = {    
    //     cMapGroupId : item.cMapGroupId,
    //     cBuildingId : item.cBuildingId,
    //     cMapId : item.cMapId,
    //     cUserId : item.cUserId,
    //   };

      
    //   axios
    //     .post(BasicInfo.UIL_API + "GetMapDetailUserEnterReq", posData, {
    //       headers: {
    //         Authorization: this.$store.state.UserInfo.Token
    //       }
    //     })
    //     .then(res => {
    //       if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }          
    //       if (res.data.ResultCd === "00") {            
    //         if (res.data.Result.length == 0) {
    //           this.NowMoreUserInfo.cTypeNm = "비인가진입";
    //           // EventBus.$emit("onShowMsg", false, item.cUserNm + "(님) 비인가 진입입니다.");            
              
    //         } else {            
    //           this.NowMoreUserInfo.cTypeNm = "일반출입";
    //           if (res.data.Result[0].cType == "J") {this.NowMoreUserInfo.cTypeNm = "작업출입";}
    //           this.NowMoreUserInfo.cReqMessage = res.data.Result[0].cReqMessage;
    //           this.NowMoreUserInfo.cIdx = res.data.Result[0].cIdx;
    //           this.NowMoreUserInfo.cType = res.data.Result[0].cType;
    //         }                         
    //       } else {
    //         EventBus.$emit("onShowMsg", true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
    //       }
    //     })
    //     .catch(err => {
    //       EventBus.$emit("onShowMsg", true, "(07)"+err);
    //     });

    // },

    getEvent() {      
      this.zEventInfo = [];

      var posData = {
        cUserId: this.$store.state.UserInfo.UserId
      };
      axios
        .post(BasicInfo.UIL_API + "GetEventInfo", posData, {
          headers: {                        
            Authorization: this.$store.state.UserInfo.Token,
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
                    
          if (res.data.ResultCd === "00") {
            this.zEventInfo = res.data.Result;
            for (var i = 0; i < this.zEventInfo.length; i++) {
              this.zEventInfo[i].bCheck = this.zEventInfo[i].cUse == "Y"; 
            }            
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, "(08)"+err);
        });
    },

    bCheckAlarm(sId){        
      for (var i = 0; i < this.zEventInfo.length; i++) {        
        if (this.zEventInfo[i].cEventId == sId) {          
          return this.zEventInfo[i].bCheck;
        }        
      }
      return false;
    },

    CheckAllEV03(){
      if (this.isAlertEvent) {
        this.$refs.SetEventCheckRef.Show("-1");
      }      
    },

    BackMap(){
      var cMapGroupId = "00"
      var cBuildingId = "00"
      var cMapId = "00"

      if (this.sNowMapId != "00") {
        cMapGroupId = this.sNowMapGroupId;
        cBuildingId = this.sNowBuildingId;
      } else if (this.sNowBuildingId != "00") {
        cMapGroupId = this.sNowMapGroupId;        
      }

      this.SelectPoi({cType:"MAP", cApId:cMapGroupId + cBuildingId + cMapId });          
    },

    onClick_3D_Map(sType, sData, cSensorId) {
      console.log("onClick_3D_Map", sType, sData, cSensorId);

      if (sType == "Sensor" ) {
        EventBus.$emit("onSensorLog", cSensorId);
      }
    },
    
    onShowMsg(bType, sMsg) {
      EventBus.$emit("onShowMsg",bType, sMsg);      
    },   

    onResize2(){
      let element1 = document.getElementById('mapWindow');      

      if (element1 != null) {        
        this.mapWidth = element1.clientWidth;
        this.mapHeight = element1.clientHeight;        
        this.$forceUpdate();
      } 
    },

    getALLData(){
      this.zApPos = [];
      var posData = {    
        cMapId : "00",
        cMapGroupId : "00",
        cBuildingId : "00",
      };
      
      axios
        .post(BasicInfo.UIL_API + "GetApPos", posData, {
          headers: {                        
            Authorization: this.$store.state.UserInfo.Token,
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {                      
            for (let i = 0; i < res.data.Result.length; i++) {
              var item = {
                cApId: res.data.Result[i].cApId,
                x : res.data.Result[i].x,
                y : res.data.Result[i].y,
                cPosition : res.data.Result[i].cPosition,
                cMac : res.data.Result[i].cMac,
                cType: res.data.Result[i].cType,
                cMapGroupId : res.data.Result[i].cMapGroupId,
                cBuildingId : res.data.Result[i].cBuildingId,
                cMapId : res.data.Result[i].cMapId,
                cSel : res.data.Result[i].cSel,
                cBuildingType : res.data.Result[i].cBuildingType,
                iCount : 0,
                bSel : false,                
                bAllow : true,
                bAlert : false,
              }             

              if (item.cType == "AP"){                
                if (res.data.Result[i].cInOut != "NONE"){
                  item.cSel = "N";
                }
              }
              
              if (this.bCheckMapRole(item.cMapGroupId, item.cBuildingId, item.cMapId)) {
                this.zApPos.push(item);
              }              
            }            
            this.GetDashboardData();                  
          } else {
            EventBus.$emit("onShowMsg", true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onErrorSnackbar", "(01)"+err);
        });
    },

    onSearch(){
      if (this.cNowSearchKeyWord == "") {
        return
      }
      this.$refs.MapWindowViewRef.Search(this.cNowSearchKeyWord);            
    },

    onSearchResult(sMsg) {
      EventBus.$emit("onErrorSnackbar", sMsg);
    },

    onPOIClick(cType, cPOIId) {
      console.log("onPOIClick", cPOIId, cType);
      
    },

    changeTab() {
      router.push({name: "Monitoring"});      
    },    

    onResize () {
      this.isCardShow1 = false;

      try {
        this.windowSize = { x: this.$refs.imageContainer.offsetWidth, y: this.$refs.imageContainer.offsetHeight };        
        this.centerContent(); 
      } catch (error) {
        console.log(error);        
      }
      

      setTimeout(() => {
        this.isCardShow1 = true;
      }, 10);
    },

    getClass(item){
      if (this.limitWater > item.waterLevel) {
        return "point_now_nor";  
      } else if (this.limitWater + 20 > (item.waterLevel)) {
        return "point_now_warn";  
      }
      return "point_now_err";  
    },


    
    centerContent() {
      const container = this.$refs.imageContainer;
      const image = this.$refs.image;

      if (container) {
        const containerWidth = container.offsetWidth;
        const containerHeight = container.offsetHeight;

        const imageWidth = image.naturalWidth;
        const imageHeight = image.naturalHeight;
        const scaleToFitWidth = containerWidth / imageWidth;
        this.scale = scaleToFitWidth * 1;

        if (this.scale < 0.3) {
          this.scale = 0.3;
        }
        this.translateX = (containerWidth - imageWidth * this.scale) / 2 - 100;
        this.translateY = (containerHeight - imageHeight * this.scale) / 2 - 50;        
      }
    },
    onWheel(event) {
      event.preventDefault();

      const zoomFactor = 0.1;
      const oldScale = this.scale;
      const newScale = event.deltaY > 0 ? this.scale - zoomFactor : this.scale + zoomFactor;

      if (newScale < 0.3) return;
      this.scale = newScale;

      const rect = this.$refs.imageContainer.getBoundingClientRect();

      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;

      const offsetX = (mouseX - this.translateX) / oldScale;
      const offsetY = (mouseY - this.translateY) / oldScale;

      this.translateX -= offsetX * (newScale - oldScale);
      this.translateY -= offsetY * (newScale - oldScale);

      this.isLod1 = this.scale >1;
    },
    onMouseDown(event) {
      this.isDragging = true;
      this.dragStartX = event.clientX;
      this.dragStartY = event.clientY;
      this.initialTranslateX = this.translateX;
      this.initialTranslateY = this.translateY;
    },
    onMouseMove(event) {
      if (!this.isDragging) return;

      const deltaX = event.clientX - this.dragStartX;
      const deltaY = event.clientY - this.dragStartY;

      this.translateX = this.initialTranslateX + deltaX;
      this.translateY = this.initialTranslateY + deltaY;
    },
    onMouseUp() {
      this.isDragging = false;
    },

    getAniClass(iWaterLev) {
      if (this.limitWater > iWaterLev) {
        return "animated-box-nor";  
      } else if (this.limitWater + 20 > (iWaterLev)) {
        return "animated-box-warn";  
      }
      return "animated-box-err";  
    },

    onGoFloor(item) {
      console.log(item);

      this.SelectPoi({cType:"MAP", cApId:item.cMapGroupId + item.cBuildingId + item.cMapId });
    },
  },
};
</script>

<style scoped>
.image-container {
  position: relative;
  width: calc(100vw - 60px);  
  height: calc(100vh - 118px);
  overflow: hidden;
  background-color: #F9F9F9;
  cursor: grab;
  user-select: none;
}
.image-container img,
.image-container svg {
  user-select: none;
  pointer-events: none;
}
.image-container:active {
  cursor: grabbing;
}

.box_3d {   
  z-index: 5;
  position: relative;
  pointer-events: auto; 
  cursor: pointer;  
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -user-select: none;  
  width:30px;
  height:30px;
  border-radius: 50%;
  padding-top: 1px;
  text-align: center;  
  background: #ffffffcc;
  border: 2px solid #202020;  
}


.floor_num_qty {   
  z-index: 3;
  position: relative;
  pointer-events: auto; 
  cursor: pointer;
  left : 30px;
  top : 15px;
  font-size: 30px;
  font-weight: 700;
  width:100px;
  height:50px;
  color: white;
  background: #0033ff90;  
  border-radius: 10px;
  border: 2px solid white;    
  box-shadow: 0 0 4px #0033ff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -user-select: none;  

  animation: heartbeat2 2s infinite;
}

.floor_num {   
  z-index: 4;
  top : 10px;
  position: relative;
  pointer-events: auto; 
  cursor: pointer;
  font-size: 40px;
  font-weight: 900;
  width:100px;
  height:50px;
  -webkit-text-stroke: 2px black;
  color: white;  
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -user-select: none;    
}


.struc_now_nor {   
  z-index: 3;
  position: relative;
  pointer-events: auto; 
  cursor: pointer;
  left : 30px;
  top : 30px;
  width : 30px;
  height : 30px;   
  background: #0033ff90;  
  border-radius: 10px;
  border: 2px solid white;    
  box-shadow: 0 0 4px #0033ff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -user-select: none;  
}

.struc_now_warn {   
  z-index: 3;
  position: relative;
  pointer-events: auto; 
  cursor: pointer;
  left : 30px;
  top : 30px;
  width : 30px;
  height : 30px;  
  background: #8a5f0950;
  border-radius: 10px;
  border: 2px solid white;    
  box-shadow: 0 0 10px #8a5f09;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -user-select: none;

  -webkit-animation: moveSlideshow 2s ease-in-out 1; 
  -moz-animation: moveSlideshow 2s ease-in-out 1;
  -o-animation: moveSlideshow 2s ease-in-out 1;

  animation: ani_point_now_warn 2s infinite;
}

.struc_now_err {   
  z-index: 3;
  position: relative;
  pointer-events: auto; 
  cursor: pointer;
  left : 30px;
  top : 30px;
  width : 30px;
  height : 30px;  
  background: #eb003bAA;
  border-radius: 10px;
  border: 2px solid white;    
  box-shadow: 0 0 10px #eb003b;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -user-select: none;

  -webkit-animation: moveSlideshow 2s ease-in-out 1; 
  -moz-animation: moveSlideshow 2s ease-in-out 1;
  -o-animation: moveSlideshow 2s ease-in-out 1;

  animation: ani_point_now_err 2s infinite;
}


.point_now_nor {   
  position: relative;
  pointer-events: auto; 
  cursor: pointer;
  left : 30px;
  top : 30px;
  width : 30px;
  height : 30px;   
  background: #0033ff90;  
  border-radius: 50px;
  border: 3px solid white;    
  box-shadow: 0 0 4px #0033ff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -user-select: none;  
}


.point_now_warn {   
  position: relative;
  pointer-events: auto; 
  cursor: pointer;
  left : 30px;
  top : 30px;
  width : 30px;
  height : 30px;  
  background: #8a5f0950;
  border-radius: 50px;
  border: 3px solid white;    
  box-shadow: 0 0 10px #8a5f09;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -user-select: none;

  -webkit-animation: moveSlideshow 2s ease-in-out 1; 
  -moz-animation: moveSlideshow 2s ease-in-out 1;
  -o-animation: moveSlideshow 2s ease-in-out 1;

  animation: ani_point_now_warn 2s infinite;
}
@keyframes ani_point_now_warn {
  0% {
    outline: 0px solid #8a5f09AA;    
  }
  100% {
    outline: 30px solid #8a5f0910;    
  }
}  

 
.point_now_err {   
  position: relative;
  pointer-events: auto; 
  cursor: pointer;
  left : 30px;
  top : 30px;
  width : 30px;
  height : 30px;  
  background: #eb003bAA;
  border-radius: 50px;
  border: 3px solid white;    
  box-shadow: 0 0 10px #eb003b;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -user-select: none;

  -webkit-animation: moveSlideshow 2s ease-in-out 1; 
  -moz-animation: moveSlideshow 2s ease-in-out 1;
  -o-animation: moveSlideshow 2s ease-in-out 1;

  animation: ani_point_now_err 2s infinite;
}
@keyframes ani_point_now_err {
  0% {
    outline: 0px solid #eb003bAA;    
  }
  100% {
    outline: 30px solid #eb003b10;    
  }
}  

.selbtn {
  outline: 1.5px solid var(--primary-color) !important;
  background: #246beb20;
}

.display_icon {
  z-index: 2;
  position: relative; top:38px; left:0px; width:90px;
  font-size:14px;
  color : black;  
  font-weight: 600;  
  padding-left: 3px;    
  padding-right: 3px;  
  border-radius: 4px; border: 1px solid #00000020;

  background: #ffffffcc;
}


@keyframes heartbeat2 {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(1.30);
            transform: scale(1.30);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}


.tab {
  float: left;
  height: 31px;
  margin-top: 5px;
  margin-right: 5px;
  /* margin: 0 8px -1px 0; */
  border: 1px solid #DAE0E7;
  background: #FFFFFF;
  /* background: #F9F9F9; */
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  font-size: 14px;
  /* transition: all .8s; */
}

.tab:hover {
  background: #fff;  
}



.box_map_left{
  overflow-x: auto; 
  overflow-y: auto; 
  width:100%; 
  height: calc(100vh - 390px);   
  padding:10px; 
  background-color:#ffffff;
}

.box_map_left2{
  overflow-x: auto; 
  overflow-y: auto; 
  width:100%; 
  height: calc(100vh - 172px); 
  padding:10px; 
  background-color:#ffffff;

}


.box_map_left::-webkit-scrollbar {
    width: 10px; 
}
 
.box_map_left::-webkit-scrollbar-thumb {
    background : #FFFFFFFF;
}

.box_map_left::-webkit-scrollbar-track {
    background: #FFFFFF33; /*스크롤바 트랙 색상*/
}



.box_sel_info_floor{
  z-index: 10;
  position:fixed;   
  top : 93px;
  left : calc(100vw - 730px);
  width : 400px;
  /* height : 500px; */
  border-radius: 8px;  
  color: #000000EE;
}

.box_sel_info_building{
  z-index: 10;  
  position:fixed;   
  top : 93px;
  left : calc(100vw - 830px);
  width : 500px;
  /* height : 500px;   */
  /* background: #FFFFFF; */  
  color: #000000EE;
  /* box-shadow: 2px 2px 1px 1px #55555522; */
}


.alert_box {  
  z-index: 20;
  position: absolute;
  margin: auto;
  width : 520px;
  top: 250px;
  left: 50%;
  transform: translate(-50%,-50%);
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  justify-content: center;
  background: #EE3300;    
  text-align: center;    
  color: white;
  border-radius: 10px;  
  font-size : 26px;
  font-weight: bold;
  /* text-shadow: -2px 0 #EE3300,0 2px #EE3300,2px 0 #EE3300,0 -2px #EE3300; */
  box-shadow: 0 0 50px #00000080;
}


.event_box {  
  z-index: 12;
  position: absolute;
  margin: auto;
  width : 520px;
  top: 350px;
  left: 50%;
  transform: translate(-50%,-50%);
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  justify-content: center;
  background: #EE3300;    
  text-align: center;    
  color: white;
  border-radius: 10px;  
  font-size : 26px;
  font-weight: bold;
  /* text-shadow: -2px 0 #EE3300,0 2px #EE3300,2px 0 #EE3300,0 -2px #EE3300; */
  box-shadow: 0 0 50px #00000080;
}

</style>
